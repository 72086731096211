import {
  QuoteGetSwapModeEnum,
  QuoteResponse,
  createJupiterApiClient,
} from "@jup-ag/api";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { Connection, VersionedTransaction } from "@solana/web3.js";
import { toast } from "react-toastify";

import { BaseAggregator } from "Interfaces/baseAggregator";
import { store } from "Store";
import { QouteResponse } from "Types/tokens";
import {
  formatSlippageForJupiterApi,
  parseUnits,
  stripDecimalsIfMore,
} from "Utils/format";
import CachedService from "./cachedService";
import {
  SlippageExceededToast,
  SwapSuccessToast,
  TxCanceledToast,
  TxFailedToast,
  TxProgressToast,
  TxSignToast,
  TxSimulationFailToast,
} from "Components/toasts";
import {
  incrementSuccessTxCount,
  resetAmounts,
  setIsSwapConfirmModalOpen,
  setPercentage,
  setQoute,
  setUserUpdatedPoints,
} from "Store/Reducers/session";
import { Percentage } from "Types/reducers";
import {
  setIsApprovalPending,
  setIsTxInProgress,
  setQouteLoading,
} from "Store/Reducers/loadings";
import axios from "axios";
import { fetchUserScore, getComputeBudget } from "Utils/fetchers";
import {
  TxFailChore,
  transactionSenderAndConfirmationWaiter,
} from "Utils/txSender";
import {
  JUPITER_QOUTE_BASEURL,
  LEADERBOARD_BASEURL,
} from "Constants/endpoints";
import {
  checkForSpecificError,
  checkIfwSOLAddress,
  simulateTransaction,
} from "Utils/judger";
import { logsErrors } from "Types/misc";

const jupiterApi = createJupiterApiClient();

export class Jupiter extends BaseAggregator {
  protected async getQuote(): Promise<QuoteResponse> {
    const state = store.getState();
    const { tokenA, tokenB, amountA, amountB, swapMode } = state.session;
    const { slippage, currentSlippage, versionedTx, onlyDirectRoute } =
      state.app;

    const formattedSlippage = formatSlippageForJupiterApi(slippage);

    let swapAmount =
      swapMode === QuoteGetSwapModeEnum.ExactIn ? amountA : amountB;

    const swapDecimals =
      swapMode === QuoteGetSwapModeEnum.ExactIn
        ? tokenA.decimals
        : tokenB.decimals;

    const amount = parseUnits(
      stripDecimalsIfMore(swapAmount, swapDecimals),
      swapDecimals
    ).toNumber();

    try {
      const quote = await jupiterApi.quoteGet({
        inputMint: checkIfwSOLAddress(tokenA.address),
        outputMint: checkIfwSOLAddress(tokenB.address),
        amount,
        slippageBps: formattedSlippage[currentSlippage],
        onlyDirectRoutes: onlyDirectRoute,
        asLegacyTransaction: !versionedTx,
        swapMode,
      });

      if (!quote) {
        throw Error("Unable to quote");
      }

      return quote;
    } catch (error) {
      console.error(`Jupiter[getQuote]: ${error}`);
      throw error;
    }
  }

  async getRoutePlan() {
    store.dispatch(setQouteLoading(true));
    const quote = await this.getQuote();
    CachedService.startQouteTimer();
    return quote;
  }

  async sendTransaction(
    qoute: QouteResponse,
    wallet: WalletContextState,
    connection: Connection
  ) {
    try {
      store.dispatch(setIsTxInProgress(true));
      const state = store.getState();
      const { tokenA, amountA, tokenB, amountB } = state.session;
      const { versionedTx } = state.app;
      const swapResult = await jupiterApi.swapPost({
        swapRequest: {
          quoteResponse: qoute,
          userPublicKey: wallet.publicKey?.toBase58() as string,
          asLegacyTransaction: !versionedTx,
          dynamicComputeUnitLimit: true,
          // prioritizationFeeLamports: "auto",
          computeUnitPriceMicroLamports: "auto",
        },
      });

      const swapTransactionBuf = Buffer.from(
        swapResult.swapTransaction,
        "base64"
      );
      const transaction = VersionedTransaction.deserialize(swapTransactionBuf);
      const error = await simulateTransaction(connection, transaction);
      if (error) {
        // Simulation error, we can check the logs for more details
        console.log("Simulation started but resulted in Error:");
        console.error(error);
        TxFailChore(<TxSimulationFailToast />);
      } else if (wallet.signTransaction) {
        CachedService.TxProgressToast(<TxSignToast />);
        store.dispatch(setIsApprovalPending(true));
        await wallet
          .signTransaction(transaction)
          .then(async (signedTx) => {
            toast.dismiss();
            CachedService.TxProgressToast(<TxProgressToast />);
            store.dispatch(setIsApprovalPending(false));
            const { blockhash, lastValidBlockHeight } =
              await connection.getLatestBlockhash();
            const transactionResponse =
              await transactionSenderAndConfirmationWaiter({
                connection,
                serializedTransaction: signedTx.serialize(),
                blockhashWithExpiryBlockHeight: {
                  blockhash,
                  lastValidBlockHeight,
                },
              });

            // If we are not getting a response back, the transaction has not confirmed.
            if (!transactionResponse) {
              console.error("Transaction not confirmed");
              TxFailChore(<TxFailedToast />);
              return;
            }

            if (transactionResponse.meta?.err) {
              TxFailChore(<TxFailedToast />);
              console.error(
                "transactionResponse in error",
                transactionResponse
              );
              return;
            }

            if (transactionResponse) {
              const txId = transactionResponse.transaction.signatures[0];
              console.log("success transactionResponse", transactionResponse);
              toast.dismiss();
              CachedService.successToast(
                <SwapSuccessToast
                  txId={txId}
                  amountA={amountA}
                  amountB={amountB}
                  tokenA={tokenA}
                  tokenB={tokenB}
                />
              );
              store.dispatch(setQoute(undefined));
              store.dispatch(incrementSuccessTxCount());
              store.dispatch(resetAmounts());
              store.dispatch(setPercentage(Percentage._0));
            }
          })
          .catch((err) => {
            console.log("sign transaction failed", err);
            store.dispatch(setIsApprovalPending(false));
            TxFailChore(<TxCanceledToast />);
          });
      }
    } catch (error: any) {
      console.log("error", error.name, error.message);
      TxFailChore(<TxFailedToast />);
    }
    store.dispatch(setIsTxInProgress(false));
  }

  async sendTransaction2(
    qoute: QouteResponse,
    wallet: WalletContextState,
    connection: Connection
  ) {
    try {
      store.dispatch(setIsTxInProgress(true));
      const state = store.getState();
      const { tokenA, amountA, amountB, tokenB } = state.session;
      const { versionedTx, useWSOL } = state.app;
      const { microLamports } = await getComputeBudget();
      let swapResult = await axios.post(
        `${JUPITER_QOUTE_BASEURL}/swap-instructions`,
        {
          quoteResponse: qoute,
          userPublicKey: wallet.publicKey,
          dynamicComputeUnitLimit: true,
          asLegacyTransaction: !versionedTx,
          // prioritizationFeeLamports: "auto",
          computeUnitPriceMicroLamports: microLamports,
          wrapAndUnwrapSol: !useWSOL,
        }
      );

      if (!swapResult.data.cleanupInstruction) {
        delete swapResult.data.cleanupInstruction;
      }
      const res = await axios.post(
        `${LEADERBOARD_BASEURL}/swap/get-transaction`,
        {
          swapInstructions: {
            userKey: wallet.publicKey,
            ...swapResult.data,
          },
        }
      );

      const swapTransactionBuf = Buffer.from(res.data.result, "base64");
      const transaction = VersionedTransaction.deserialize(swapTransactionBuf);

      const error = await simulateTransaction(connection, transaction);
      if (error) {
        console.log("Simulation started but resulted in Error:");
        console.error(error);
        TxFailChore(<TxSimulationFailToast />);
      } else if (wallet.signTransaction) {
        CachedService.tokenAmountA = amountA;
        CachedService.tokenAmountB = amountB;
        CachedService.TxProgressToast(<TxSignToast />);
        store.dispatch(setIsApprovalPending(true));
        await wallet
          .signTransaction(transaction)
          .then(async (signedTx) => {
            toast.dismiss();
            CachedService.TxProgressToast(<TxProgressToast />);
            store.dispatch(setIsApprovalPending(false));
            const { blockhash, lastValidBlockHeight } =
              await connection.getLatestBlockhash();
            const transactionResponse =
              await transactionSenderAndConfirmationWaiter({
                connection,
                serializedTransaction: signedTx.serialize(),
                blockhashWithExpiryBlockHeight: {
                  blockhash,
                  lastValidBlockHeight,
                },
              });

            // If we are not getting a response back, the transaction has not confirmed.
            if (!transactionResponse) {
              console.error("Transaction not confirmed");
              TxFailChore(<TxFailedToast />);
              return;
            }

            if (transactionResponse.meta?.err) {
              if (
                checkForSpecificError(transactionResponse, logsErrors._6001)
              ) {
                TxFailChore(<SlippageExceededToast />);
              } else {
                TxFailChore(<TxFailedToast />);
              }
              console.error(
                "transactionResponse in error",
                transactionResponse
              );
              return;
            }

            if (transactionResponse) {
              const txId = transactionResponse.transaction.signatures[0];
              const score = await fetchUserScore(wallet.publicKey);
              store.dispatch(setUserUpdatedPoints(score));
              console.log("success transactionResponse", transactionResponse);
              toast.dismiss();
              CachedService.successToast(
                <SwapSuccessToast
                  txId={txId}
                  amountA={amountA}
                  amountB={amountB}
                  tokenA={tokenA}
                  tokenB={tokenB}
                />
              );
              store.dispatch(setIsSwapConfirmModalOpen(true));
              store.dispatch(setQoute(undefined));
              store.dispatch(incrementSuccessTxCount());
              store.dispatch(resetAmounts());
              store.dispatch(setPercentage(Percentage._0));
            }
          })
          .catch((err) => {
            console.log("sign transaction failed", err);
            store.dispatch(setIsApprovalPending(false));
            TxFailChore(<TxCanceledToast />);
          });
      }
    } catch (error: any) {
      console.log("error", error.name, error.message);
      TxFailChore(<TxFailedToast />);
    }
    store.dispatch(setIsTxInProgress(false));
  }
}
