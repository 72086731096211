import axios from "axios";
import _ from "lodash";

import { BIRDSEYE_API_BASEURL, COINGECKO_BASEURL } from "Constants/endpoints";
import { ChartData } from "Types/misc";
import { convertIntoChartData, pickEvenlySpacedValues } from "Utils/format";
import { TokensCharts } from "Types/tokens";
import { BEChartResponse, CGChartResponse } from "Types/classes";

class ChartManagerClass {
  private chartFunctions = [this.coingecko];
  private remainingAddresses: string[] = [];

  charts: TokensCharts = {};

  constructor(addresses: string[]) {
    this.remainingAddresses = addresses;
  }

  async fetchCharts() {
    for (let i = 0; i < this.chartFunctions.length; i++) {
      if (this.remainingAddresses.length === 0) break;
      const res = await this.chartFunctions[i](this.remainingAddresses);
      this.remainingAddresses = res.remaining;
      this.charts = { ...this.charts, ...res.response };
    }
    return this.charts;
  }

  async coingecko(addresses: string[]) {
    const fetchChart = async (
      address: string
    ): Promise<[string, ChartData | null]> => {
      try {
        const currentTime = new Date().getTime() / 1000;
        const past24hr =
          new Date(new Date().getTime() - 24 * 60 * 60 * 1000).getTime() / 1000;
        const response = await axios.get<CGChartResponse>(
          `${COINGECKO_BASEURL}coins/solana/contract/${address}/market_chart/range?vs_currency=usd&from=${past24hr}&to=${currentTime}`,
          {
            headers: {
              "x-cg-demo-api-key": process.env.REACT_APP_COINGECKO_API_KEY,
            },
          }
        );
        if (response.status === 200 && !_.isEmpty(response.data)) {
          return [address, pickEvenlySpacedValues(response.data.prices)];
        }
        return [address, null];
      } catch (error) {
        return [address, null];
      }
    };

    const promises = addresses.map((address) => fetchChart(address));
    const results = await Promise.all(promises);

    return processPromises(results);
  }

  async birdsEye(addresses: string[]) {
    const currentTime = new Date().getTime();
    const past24hr = new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000
    ).getTime();
    const fetchChart = async (
      address: string
    ): Promise<[string, ChartData | null]> => {
      try {
        const response = await axios.get<BEChartResponse>(
          `${BIRDSEYE_API_BASEURL}history_price?address=${address}&address_type=token&time_from=${past24hr}&time_to=${currentTime}`,
          {
            headers: {
              "X-API-KEY": process.env.REACT_APP_BIRDSEYE_API_KEY as string,
            },
          }
        );
        if (response.status === 200 && !_.isEmpty(response.data)) {
          return [
            address,
            convertIntoChartData(
              pickEvenlySpacedValues(response.data.data.items),
              "value",
              "unixTime"
            ),
          ];
        }
        return [address, null];
      } catch (error) {
        return [address, null];
      }
    };

    const promises = addresses.map((address) => fetchChart(address));
    const results = await Promise.all(promises);

    return processPromises(results);
  }
}

function processPromises(promiseResults: [string, ChartData | null][]) {
  const chartRecord: TokensCharts = {};
  const remainingTokens: string[] = [];
  promiseResults.forEach(([address, chartData]) => {
    if (chartData) {
      chartRecord[address] = [...chartData];
    } else {
      remainingTokens.push(address);
    }
  });
  return { remaining: remainingTokens, response: chartRecord };
}

export default ChartManagerClass;
