import { useEffect, useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";

import sortUp from "Assets/Svgs/sort_up.svg";
import sortDown from "Assets/Svgs/sort_down.svg";
import { conciseAddress } from "Utils/trunc";
import { CopyText } from "./copyText";
import LeftPanel from "Assets/Svgs/left_panel.svg";
import RightPanel from "Assets/Svgs/right_panel.svg";
import { leaderboard } from "Utils/fetchers";
import { GITBOOK } from "Constants/misc";
import { dispatch } from "Store";
import { setIsSwapConfirmModalOpen } from "Store/Reducers/session";

const LeaderBoardTable = () => {
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    const fetch = async () => {
      const data = await leaderboard();
      setData(data);
    };
    dispatch(setIsSwapConfirmModalOpen(false));
    fetch();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Rank",
        accessor: "rank",
        Cell: ({ value }: { value: number }) => {
          if (value === 1 || value === 2 || value === 3) {
            return (
              <div className="flex items-center justify-center gap-1">
                <img src={LeftPanel} alt="left-panel" />
                {value}
                <img src={RightPanel} alt="right-panel" />
              </div>
            );
          } else {
            return <div>{value}</div>;
          }
        },
      },
      {
        Header: "Wallet Address",
        accessor: "walletKey",
        Cell: ({ value }: { value: string }) => {
          return (
            <div className="flex items-center justify-center gap-1">
              {conciseAddress(value, 9, 9)}
              <div className="cursor-pointer">
                {" "}
                <CopyText text={value} />
              </div>
            </div>
          );
        },
      },
      { Header: "Tx Count", accessor: "tradesCount", className: "cell-center" },
      {
        Header: "Volume",
        accessor: "totalVolume",
        Cell: ({ value }: { value: number }) => {
          return (
            <div className="text-center font-medium" title={value.toString()}>
              ${Number(value ?? 0).toFixed(2)}
            </div>
          );
        },
      },
      {
        Header: "Score",
        id: "score",
        accessor: "totalVolume",
        Cell: ({ value }: { value: number }) => {
          return (
            <div
              className="mesh-text-gradient text-center font-semibold"
              title={value.toString()}
            >
              {value?.toFixed(2)}
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    //@ts-ignore
    setGlobalFilter,
    //@ts-ignore
    page,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    previousPage,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    canPreviousPage,
    //@ts-ignore
    pageOptions,
    //@ts-ignore
    gotoPage,
    //@ts-ignore
    pageCount,
  } = useTable(
    {
      columns,
      data: data,
      initialState: {
        //@ts-ignore
        pageIndex: 0,
        sortBy: [
          {
            id: "rank",
            desc: false,
          },
        ],
      }, // Start at the first page
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  //@ts-ignore
  const { globalFilter, pageIndex } = state;

  return (
    <div className="px-12 mt-4 w-full md:w-[1350px] max-w-[1350px]">
      <div className="flex flex-col md:flex-row justify-between md:items-center mb-4">
        <div>
          <div className="text-xl font-semibold">Leaderboard</div>
          <div className="text-sm text-secondary">
            Earn rewards by trading on Mesh Aggregator.{" "}
            <a href={GITBOOK} className="underline">
              Learn More.
            </a>
          </div>
        </div>
        <div className="mt-2 md:mt-0">
          <div className="text-base font-medium mb-1">Search</div>
          <input
            className="search-field p-2 outline-none bg-base-content rounded placeholder:text-xs"
            type="search"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Enter wallet address"
          />
        </div>
      </div>
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-gray-600 rounded overflow-x-scroll"
      >
        <thead className="bg-base-content">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  // @ts-ignore
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-6 py-7 text-sm font-medium text-center text-secondary capitalize tracking-wider"
                >
                  {/* {column.render("Header")} */}
                  <HeaderSort column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="divide-y divide-gray-600 text-sm font-medium text-center"
        >
          {page.map((row: any, i: number) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="bg-base-content">
                {row.cells.map((cell: any) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-5 whitespace-nowrap"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="py-4 flex justify-end items-center bg-base-content">
        <div>
          {/* <button
            onClick={() => gotoPage(0)}
            disabled={pageIndex === 0}
            className={`px-3 py-1 rounded-md mr-2 ${
              pageIndex === 0
                ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                : "bg-blue-500 text-white"
            }`}
          >
            {"<<"}
          </button> */}
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={`px-3 py-1 mr-2 font-bold border border-gray-600 ${
              !canPreviousPage
                ? "text-gray-600 cursor-not-allowed"
                : "text-primary"
            }`}
          >
            {"<"}
          </button>
        </div>
        <div>
          {pageOptions.map((page: any, i: number) => (
            <button
              key={i}
              onClick={() => gotoPage(i)}
              className={`px-3 py-1  mr-2 font-bold border border-gray-600 ${
                pageIndex === i
                  ? "mesh-text-gradient w-fit gradient-border-pagination"
                  : "text-primary"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
        <div>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={`px-3 py-1 mr-2 border font-bold border-gray-600 ${
              !canNextPage ? "text-gray-600 cursor-not-allowed" : "text-primary"
            }`}
          >
            {">"}
          </button>
          {/* <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageIndex === pageCount - 1}
            className={`px-3 py-1 rounded-md ${
              pageIndex === pageCount - 1
                ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                : "bg-blue-500 text-white"
            }`}
          >
            {">>"}
          </button> */}
        </div>
      </div>
      {/* <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className={`px-3 py-1 rounded-md ${
            !canPreviousPage
              ? "bg-gray-300 text-gray-600 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
        >
          Previous
        </button>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {Math.ceil(rows.length / 10)}{" "}
          </strong>{" "}
        </span>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className={`px-3 py-1 rounded-md ${
            !canNextPage
              ? "bg-gray-300 text-gray-600 cursor-not-allowed"
              : "bg-blue-500 text-white"
          }`}
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default LeaderBoardTable;

export interface HeaderSortProps {
  column: any;
  sort?: boolean;
}
export const HeaderSort = ({ column, sort }: HeaderSortProps) => {
  return (
    <div
      className="flex items-center justify-center"
      {...(sort && { ...column.getHeaderProps(column.getSortByToggleProps()) })}
    >
      <div className="text-sm">{column.render("Header")}</div>
      {!column.disableSortBy && (
        <div className="text-secondary ml-1 flex flex-col justify-center items-center">
          <img
            src={sortUp}
            className="mb-1"
            width={column.isSorted && !column.isSortedDesc ? 10 : 8}
          />
          <img src={sortDown} width={column.isSortedDesc ? 10 : 8} />
        </div>
      )}
    </div>
  );
};
