import pako from "pako";

import { JUPITER_PRICING_API } from "Constants/endpoints";
import {
  JupiterPricingResponse,
  TokenInfo,
  UserTokenBalances,
} from "Types/tokens";
import { MESH_ADDRESS } from "Constants/tokens";

export const sortUserTokens = (
  allTokens: TokenInfo[],
  connected: boolean,
  balances: UserTokenBalances
) => {
  if (!connected) {
    return allTokens;
  } else {
    return allTokens.sort((a, b) => {
      const amountA = balances[a.address]?.amount ?? 0;
      const amountB = balances[b.address]?.amount ?? 0;
      return Number(amountB) - Number(amountA);
    });
  }
};

export const fetchUserTokenDollarVal = async (
  address: string,
  balance: number
) => {
  try {
    const response: JupiterPricingResponse = await fetch(
      `${JUPITER_PRICING_API}${address}`
    ).then((res) => res.json());
    if (response?.data) {
      return response.data[address].price * balance;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const fetchMeshPrice = async () => {
  try {
    const response: JupiterPricingResponse = await fetch(
      `${JUPITER_PRICING_API}${MESH_ADDRESS}`
    ).then((res) => res.json());
    if (response?.data) {
      return response.data[MESH_ADDRESS].price;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const fetchPriceFromJupiter = async (address: string) => {
  try {
    const response: JupiterPricingResponse = await fetch(
      `${JUPITER_PRICING_API}${address}`
    ).then((res) => res.json());
    if (response?.data) {
      return response.data[address].price;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const decodeTokenDetails = (encodedString: string) => {
  const compressedBytes = Buffer.from(encodedString, "base64");
  const jsonString = pako.ungzip(compressedBytes, { to: "string" });
  const tokenDetails = JSON.parse(jsonString);

  return tokenDetails;
};
