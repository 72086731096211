import { lazy, useEffect } from "react";
import {
  useNavigate,
  useRoutes,
  Navigate,
  useLocation,
} from "react-router-dom";

import CachedService from "../Classes/cachedService";
import MainLayout from "Layout/mainlayout";
import LeaderBoard from "Pages/Leaderboard";
import { dispatch } from "Store";
import { setTokenA, setTokenB } from "Store/Reducers/session";
import { decodeTokenDetails } from "Utils/helpers";
const Home = lazy(() => import("Pages/Home"));

export default function Routes() {
  const navigate = useNavigate();
  CachedService.navigation = navigate;
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      const queryParams = new URLSearchParams(search).get("data");
      if (queryParams) {
        const decodedTokenDetails = decodeTokenDetails(queryParams);
        const {
          tokenA,
          tokenB,
          tokenADecimals,
          tokenBDecimals,
          tokenAImg,
          tokenBImg,
          tokenAName,
          tokenBName,
          tokenASymbol,
          tokenBSymbol,
        } = decodedTokenDetails;
        dispatch(
          setTokenA({
            address: tokenA,
            chainId: 101,
            decimals: Number(tokenADecimals),
            name: tokenAName,
            symbol: tokenASymbol,
            logoURI: tokenAImg,
            tags: ["old-registory"],
          })
        );
        dispatch(
          setTokenB({
            address: tokenB,
            chainId: 101,
            decimals: Number(tokenBDecimals),
            name: tokenBName,
            symbol: tokenBSymbol,
            logoURI: tokenBImg,
            tags: ["old-registory"],
          })
        );
      }
    }
  }, []);
  return useRoutes([
    { index: true, element: <Navigate to={"/swap"} replace={true} /> },
    {
      path: "/",
      element: <MainLayout />,
      children: [{ path: "swap", element: <Home /> }],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [{ path: "leaderboard", element: <LeaderBoard /> }],
    },
    { path: "*", element: <Navigate to={"/swap"} replace={true} /> },
  ]);
}
