import { QuoteGetSwapModeEnum } from "@jup-ag/api";
import {
  QouteResponse,
  TokenInfo,
  TokenListsType,
  TokensCharts,
  TokensPrices,
  UserTokenBalances,
  UserTokens,
  token_selection_type,
} from "./tokens";

export type SessionReducer = {
  userTokens: UserTokens;
  userTokenBalances: UserTokenBalances;
  tokensListType: TokenListsType;
  tokenA: TokenInfo;
  tokenB: TokenInfo;
  percentage: Percentage;
  amountA: string;
  initialAmountA: string;
  amountB: string;
  initialAmountB: string;
  swapMode: QuoteGetSwapModeEnum;
  userBalanceA: string;
  userBalanceB: string;
  selectedToken: token_selection_type;
  isTokenListOpen: boolean;
  qoute?: QouteResponse;
  isFirstQoute: boolean;
  successTxCount: number;
  meshPrice: number;
  isSwapSettingsOpen: boolean;
  swapErrors: string[];
  currentPrices: TokensPrices;
  currentCharts: TokensCharts;
  isSwapConfirmedModalOpen: boolean;
  userInitialPoints: number;
  userUpdatedPoints: number;
  isWrappingSOL: boolean;
  isUnwrappingSOL: boolean;
  swapWarnings: string[];
  isInSufficientBalance: boolean;
};

export type LoadingsReducer = {
  tokensLoading: boolean;
  tokensFetching: boolean;
  qouteLoading: boolean;
  balanceLoading: boolean;
  isTxInProgress: boolean;
  isPriceLoading: boolean;
  isApprovalPending: boolean;
  isChartLoading: boolean;
};

export type SlippageValues = {
  [key in keyof typeof SlippageLevels]: key extends "custom" ? string : number;
};

export type AppReducer = {
  lastTokensUpdated: number;
  slippage: SlippageValues;
  currentSlippage: SlippageLevels;
  onlyDirectRoute: boolean;
  versionedTx: boolean;
  currentExplorer: string;
  currentRPC: string;
  isCustomRPC: boolean;
  priorityLevel: PriorityLevel;
  priorityMode: PriorityMode;
  maxCap: number | undefined;
  exactFee: number | undefined;
  PriorityFeeLevelsValues: Record<PriorityLevel, number>;
  useWSOL: boolean;
};

export enum SlippageLevels {
  low = "low",
  medium = "medium",
  high = "high",
  custom = "custom",
}

export enum Percentage {
  _0 = 0,
  _10 = 0.1,
  _25 = 0.25,
  _50 = 0.5,
  _75 = 0.75,
  _100 = 1,
}

export enum PriorityLevel {
  AUTO = "Auto",
  FAST = "Fast",
  TURBO = "Turbo",
  ULTRA = "Ultra",
}

export enum PriorityMode {
  MAX_CAP = "Max Cap",
  EXACT_FEE = "Exact Fee",
}
