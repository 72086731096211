import { WalletContextState } from "@solana/wallet-adapter-react";
import { Connection } from "@solana/web3.js";
import { QouteResponse } from "Types/tokens";

export interface SwapData {
  inputMint: string;
  outputMint: string;
  amount: number;
}

export interface RoutePlan {
  exchange: string;
  inputMint: string;
  outputMint: string;
  amountIn: number;
  amountOut: number;
}

export abstract class BaseAggregator {
  protected abstract getQuote(): Promise<unknown>;
  abstract getRoutePlan(): Promise<QouteResponse>;
  abstract sendTransaction(
    qoute: QouteResponse,
    wallet: WalletContextState,
    connection: Connection
  ): void;
}
