import { useSelector } from "Store";
import { TokenListsType } from "Types/tokens";

const CommonClass =
  "tab text-primary font-semibold w-24 rounded h-[unset] border-2 border-base-100";

export const Tabs = ({
  handleList,
}: {
  handleList: (list: TokenListsType) => void;
}) => {
  const { tokensListType } = useSelector((state) => state.session);
  const changeTab = (tab: TokenListsType) => {
    handleList(tab);
  };
  return (
    <div className="flex justify-center bg-base-content px-1.5 py-1.5 rounded mt-2 ">
      <div
        className={`${CommonClass} ${
          tokensListType === TokenListsType.strict ? "bg-base-100" : ""
        }`}
        onClick={() => changeTab(TokenListsType.strict)}
      >
        <p
          className={`my-1.5 ${
            tokensListType === TokenListsType.strict ? "tab-active" : ""
          }`}
        >
          Strict
        </p>
      </div>
      <div
        className={`${CommonClass} ${
          tokensListType === TokenListsType.all ? "bg-base-100" : ""
        }`}
        onClick={() => changeTab(TokenListsType.all)}
      >
        <p
          className={`my-1.5 ${
            tokensListType === TokenListsType.all ? "tab-active" : ""
          }`}
        >
          All
        </p>
      </div>
    </div>
  );
};
