import {
  Connection,
  PublicKey,
  VersionedTransaction,
  VersionedTransactionResponse,
} from "@solana/web3.js";

import { NATIVE_SOL_TOKENINFO, WSOL_TOKENINFO } from "Constants/tokens";
import { logsErrors } from "Types/misc";
import { QouteResponse } from "Types/tokens";

export const validateSolAddress = (address: string) => {
  try {
    let pubkey = new PublicKey(address);
    let isSolana = PublicKey.isOnCurve(pubkey.toBuffer());
    return isSolana;
  } catch (error) {
    return false;
  }
};

export const calculateInputWidth = (str: string) => {
  // Check if the string contains a dot
  const containsDot = str.includes(".");

  // Count the number of '1's in the string
  const countOfOnes = str.split("").filter((char) => char === "1").length;
  const totalLengthWithDot = containsDot ? str.length - 1 : str.length;

  const inputWidth =
    totalLengthWithDot -
    countOfOnes +
    countOfOnes * 0.75 +
    (containsDot ? 0.5 : 0);

  return Number(inputWidth.toFixed(2)) + 0.75;
};

export function validateDecimalPlaces(numStr: string, maxDecimals: number) {
  // Regular expression to check if the number has more than maxDecimals places
  const regex = new RegExp(`^\\d+(\\.\\d{0,${maxDecimals}})?$`);
  return regex.test(numStr);
}

export const countHopsAndDexes = (routes: QouteResponse) => {
  let hops = new Set();
  let labels = new Set();

  routes.routePlan.forEach((route) => {
    const inputMint = route.swapInfo.inputMint;
    const outputMint = route.swapInfo.outputMint;
    let label = route.swapInfo.label;

    // Replace "Whirlpool" with "Orca"
    if (label === "Whirlpool") {
      label = "Orca";
    }

    // Add the label to the set if it's not already present
    if (!labels.has(label)) {
      labels.add(label);
    }

    // Check if inputMint and outputMint are different and not already counted
    if (inputMint !== outputMint) {
      const mintPair = inputMint + "-" + outputMint;

      if (!hops.has(mintPair)) {
        hops.add(mintPair);
      }
    }
  });

  return { hops: hops.size, dexes: Array.from(labels) as string[] };
};

export const checkForSpecificError = (
  transactionResponse: VersionedTransactionResponse,
  errorNumber: logsErrors
) => {
  const logs = transactionResponse.meta?.logMessages;
  if (logs) {
    for (let i = logs.length - 1; i >= 0; i--) {
      const singleLog = logs[i];
      if (singleLog.includes(`Error Number: ${errorNumber}`)) {
        return true;
      }
    }
  }
  return false;
};

export const simulateTransaction = async (
  connection: Connection,
  transaction: VersionedTransaction
) => {
  let error = null;
  try {
    // We first simulate whether the transaction would be successful
    const { value: simulatedTransactionResponse } =
      await connection.simulateTransaction(transaction, {
        replaceRecentBlockhash: true,
        commitment: "processed",
      });
    console.log("simulatedTransactionResponse", simulatedTransactionResponse);
    const { err } = simulatedTransactionResponse;
    error = err;
  } catch (errorCatch) {
    console.log("Simulation Didn't even started");
    error = errorCatch;
  }
  return error;
};

export const checkIfwSOLAddress = (address: string) =>
  address === WSOL_TOKENINFO.address ? NATIVE_SOL_TOKENINFO.address : address;

export const checkIfWrapUnwrap = (
  addressTokenA: string,
  addressTokenB: string
) => {
  let localIswrapping = false;
  let localIsUnwrapping = false;

  if (
    (addressTokenA === NATIVE_SOL_TOKENINFO.address &&
      addressTokenB === WSOL_TOKENINFO.address) ||
    (addressTokenA === WSOL_TOKENINFO.address &&
      addressTokenB === NATIVE_SOL_TOKENINFO.address)
  ) {
    localIswrapping = addressTokenA === NATIVE_SOL_TOKENINFO.address;
    localIsUnwrapping = addressTokenA === WSOL_TOKENINFO.address;
  }
  return {
    isWrapping: localIswrapping,
    isUnwrapping: localIsUnwrapping,
    isWrapUnwrapIntent: localIswrapping || localIsUnwrapping,
  };
};

export const doesWebsiteMatch = (website: string) => website === window.origin;
