export const RAYDIUM_TOKENLIST =
  "https://api.raydium.io/v2/sdk/token/raydium.mainnet.json";

export const JUPITER_STRICT_LIST = "https://token.jup.ag/strict";
export const JUPITER_ALL_LIST = "https://token.jup.ag/all";
export const JUPITER_PRICING_API = "https://price.jup.ag/v4/price?ids=";
export const JUPITER_QOUTE_BASEURL = "https://quote-api.jup.ag/v6";

export const ALCHEMY_RPC =
  "https://solana-mainnet.g.alchemy.com/v2/XZ7JJ4hDzKjlD08C-ffRrd1aHSIT4QrS";

export const QUICKNODE_MAINNET_RPC =
  "https://sly-frequent-borough.solana-mainnet.quiknode.pro/fe14582373f7556294f669dae74f8a429f8ddde6/";

export const HELIUS_RPC =
  "https://mainnet.helius-rpc.com/?api-key=05d0a016-beaa-4751-8c10-7ef630710bcc";
export const ANKR_RPC =
  "https://rpc.ankr.com/solana/cd3f67409ef4a6eefb4a7fff0d2cace778d8b8ad425748d41fd40d44c71a8995";

export const COINGECKO_BASEURL = "https://api.coingecko.com/api/v3/";
export const BIRDSEYE_API_BASEURL = "https://public-api.birdeye.so/public/";
export const LEADERBOARD_BASEURL = "https://swap-server.meshprotocol.xyz";
