import React from "react";
import { useEffect, useState, useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from "lodash.debounce";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";

import { TokenInfo, TokenListsType, token_selection_type } from "Types/tokens";
import MeshIcon from "Assets/Svgs/ring-mesh.svg";
import crossIcon from "Assets/Svgs/cross.svg";
import notFound from "Assets/Svgs/not-found.svg";
import "./index.css";
import { dispatch, store, useSelector } from "Store";
import {
  fetchOnChainToken,
  forceMaxBalanceOfwSol,
  handleTokenExchange,
} from "Utils/tokens";
import {
  setAmountA,
  setAmountB,
  setIsFirstQoute,
  setIsTokenListOpen,
  setPercentage,
  setTokenA,
  setTokenB,
  setTokensListType,
  setUserTokens,
} from "Store/Reducers/session";
import { usePrevious } from "hooks/usePrevious";
import TokenCacheService from "Classes/tokenCache";
import { Tabs } from "Components/tabs";
import { sortUserTokens } from "Utils/helpers";
import { Percentage } from "Types/reducers";
import { Jupiter } from "Classes/jupiter";
import { fetchRoutePlan } from "Utils/fetchers";
import { setQouteLoading } from "Store/Reducers/loadings";
import { stripDecimalsIfMore } from "Utils/format";
import RedirectAddress from "Components/redirectAddress";
import { TwBreakPoints } from "Types/misc";
import { useTwBreakpoints } from "hooks/useTwBreakpoints";
import { WSOL_TOKENINFO } from "Constants/tokens";
import { toggleWSOL } from "Store/Reducers/app";
import { checkIfWrapUnwrap } from "Utils/judger";
import CachedService from "Classes/cachedService";

const TokenModal = () => {
  const { connection } = useConnection();
  const { connected } = useWallet();

  const tokenA = useSelector((state) => state.session.tokenA);
  const tokenB = useSelector((state) => state.session.tokenB);
  const tokensListType = useSelector((state) => state.session.tokensListType);
  const tokensLoading = useSelector((state) => state.loadings.tokensLoading);
  const tokensFetching = useSelector((state) => state.loadings.tokensFetching);
  const userTokenBalances = useSelector(
    (state) => state.session.userTokenBalances
  );
  const selectedToken = useSelector((state) => state.session.selectedToken);
  const isTokenListOpen = useSelector((state) => state.session.isTokenListOpen);
  const prevListType = usePrevious(tokensListType);
  const jupiter = useMemo(() => new Jupiter(), []);
  const screenSize = useTwBreakpoints();

  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState<TokenInfo[]>([]);

  const tokensList = useMemo(
    () =>
      tokensListType === TokenListsType.strict
        ? TokenCacheService.strictTokens
        : TokenCacheService.allTokens,
    [tokensListType, tokensLoading, tokensFetching]
  );

  useEffect(() => {
    const sortedListWithBalances = sortUserTokens(
      tokensList,
      connected,
      userTokenBalances
    );
    setList(sortedListWithBalances.slice(0, 20));
  }, [connected, tokensList, userTokenBalances]);

  useEffect(() => {
    if (!connected) {
      dispatch(setUserTokens({}));
    }
  }, [connected]);

  const debounced = useMemo(
    () =>
      debounce((value) => {
        handleKeywordChange(value, tokensList);
      }, 400),
    [tokensList]
  );

  // will trigger only if user change the token list type with some input in the search field
  useEffect(() => {
    if (
      prevListType !== undefined &&
      prevListType !== tokensListType &&
      keyword
    ) {
      handleKeywordChange(keyword, tokensList);
    }
  }, [tokensList, keyword, prevListType, tokensListType, debounced]);

  const handleKeywordChange = (value: string, tokensList: TokenInfo[]) => {
    if (!value) {
      setList(tokensList.slice(0, 20));
      return;
    }

    const filtered: TokenInfo[] = [];

    tokensList.forEach((token: TokenInfo) => {
      let weight = 0;

      if (token.symbol.toLowerCase().includes(value)) {
        weight +=
          (value.length / token.symbol.length) * 300 +
          token.symbol.length -
          token.symbol.toLowerCase().indexOf(value);
      }

      if (token.name.toLowerCase().includes(value)) {
        weight +=
          (value.length / token.name.length) * 100 +
          token.name.length -
          token.name.toLowerCase().indexOf(value);
      }

      if (value.length > 10 && token.address.toLowerCase().includes(value)) {
        weight +=
          (value.length / token.address.length) * 50 +
          token.address.length -
          token.address.toLowerCase().indexOf(value);
      }

      if (weight > 0) {
        filtered.push({ ...token, weight });
      }
    });

    if (filtered.length === 0) {
      searchTokenOnChain(value);
    } else {
      setList(
        filtered
          .sort((a, b) => (b?.weight ?? 0) - (a?.weight ?? 0))
          .slice(0, 20)
      );
    }
  };

  const searchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLocaleLowerCase();
    setKeyword(value);
    debounced(value);
  };

  const searchTokenOnChain = async (tokenAddress: string) => {
    const onChainTokenInfo = await fetchOnChainToken(tokenAddress, connection);
    if (onChainTokenInfo) {
      setList([onChainTokenInfo]);
    } else {
      setList([]);
    }
  };

  const handleTokenListChange = async (listType: TokenListsType) => {
    if (listType !== tokensListType) {
      dispatch(setTokensListType(listType));
    }
  };

  const closeTokenModal = () => {
    dispatch(setPercentage(Percentage._0));
    dispatch(setIsTokenListOpen(false));
  };

  const handleTokenSelection = (token: TokenInfo) => {
    closeTokenModal();
    const { amountA, amountB } = store.getState().session; // doing this to avoid re-renders of huge tokenList component on every amount change
    const currentToken =
      selectedToken === token_selection_type.A ? tokenA : tokenB;

    const otherToken =
      selectedToken === token_selection_type.A ? tokenB : tokenA;

    if (currentToken.address !== token.address) {
      // handle wrapping and unwrapping
      let thistokenA, thistokenB;

      // assign tokens in local tokens variables and also making sure to exchange the tokens if there is a token exchange intent by user
      if (selectedToken === token_selection_type.A) {
        thistokenA = token;
        thistokenB = tokenB.address === thistokenA.address ? tokenA : tokenB;
      } else {
        thistokenA = tokenA.address === token.address ? tokenB : tokenA;
        thistokenB = token;
      }

      const { isWrapUnwrapIntent, isUnwrapping } = checkIfWrapUnwrap(
        thistokenA.address,
        thistokenB.address
      );

      if (isWrapUnwrapIntent) {
        CachedService.stopQouteTimer();
      }

      // handle token selection
      if (token.address === otherToken.address) {
        handleTokenExchange(jupiter, isWrapUnwrapIntent);
      } else {
        const setTokenAction =
          selectedToken === token_selection_type.A ? setTokenA : setTokenB;

        const setAmountAction =
          selectedToken === token_selection_type.A ? setAmountA : setAmountB;

        dispatch(setTokenAction(token));

        // if user has unwrapping intent then we have to force the amount to be max balance of wsol on both amountA and amountB
        if (isUnwrapping) {
          forceMaxBalanceOfwSol();
        } else if (amountA !== "" || amountB !== "") {
          // do not execute anything related to qouting if there is wrapping and unwrapping intent by user
          dispatch(setIsFirstQoute(true));
          if (!isWrapUnwrapIntent) {
            dispatch(setQouteLoading(true));
          }

          dispatch(
            setAmountAction(
              // doing this incase the new selected token has less decimals than the previous one
              stripDecimalsIfMore(
                selectedToken === token_selection_type.A
                  ? isWrapUnwrapIntent
                    ? amountB
                    : amountA
                  : isWrapUnwrapIntent
                  ? amountA
                  : amountB,
                token.decimals
              )
            )
          );

          if (!isWrapUnwrapIntent) {
            fetchRoutePlan(jupiter);
          }
        }
      }
      // toggle useWSol if wSOL is selected
      if (
        token.address === WSOL_TOKENINFO.address ||
        otherToken.address === WSOL_TOKENINFO.address
      ) {
        dispatch(toggleWSOL(true));
      } else {
        dispatch(toggleWSOL(false));
      }
    }
  };

  const modalScale = useMemo(
    () =>
      screenSize === TwBreakPoints.sm || screenSize === TwBreakPoints.xs
        ? {}
        : { scale: "0.9" },
    [screenSize]
  );

  return (
    <dialog
      id="my_modal_1"
      className="modal"
      style={isTokenListOpen ? { backdropFilter: "blur(10px)" } : {}}
      open={isTokenListOpen}
      onClose={closeTokenModal}
    >
      <div
        className="modal-box gradient-border !p-[2px] !rounded-[0.25rem]"
        style={modalScale}
      >
        <div className="bg-base-content !rounded-[0.25rem] p-6">
          <div className="flex justify-between items-center ">
            <div className="text-xl font-semibold text-primary">
              Select Token
            </div>
            <img
              src={crossIcon}
              onClick={closeTokenModal}
              className="cursor-pointer accent-on-hover"
              alt="crossIcon"
            />
          </div>
          <hr className="border-l my-3 divider-class" />
          <div className="modal-tokensList">
            <div className="hd">
              <div className="search-bar">
                <input
                  value={keyword}
                  type="search"
                  placeholder="Search by token name or paste address"
                  className="search-field"
                  onChange={searchInputChange}
                />
              </div>
              <div className="flex flex-row flex-wrap items-center md:justify-between">
                {TokenCacheService.strictTokens.slice(0, 4).map((token, i) => (
                  <TokenChip
                    key={i}
                    token={token}
                    onClick={handleTokenSelection}
                  />
                ))}
              </div>
            </div>
            <hr className="border-l my-2 divider-class" />
            <TokenList
              list={list}
              setList={(newList) =>
                setList(
                  newList.concat(
                    tokensList.slice(newList.length, newList.length + 20)
                  )
                )
              }
              handleTokenSelection={handleTokenSelection}
              actualTokensListLength={tokensList.length}
            />
            <Tabs handleList={handleTokenListChange} />
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default TokenModal;

type TokenListProps = {
  list: TokenInfo[];
  setList: (list: TokenInfo[]) => void;
  handleTokenSelection: (token: TokenInfo) => void;
  actualTokensListLength: number;
};

const TokenList = ({
  list,
  setList,
  handleTokenSelection,
  actualTokensListLength,
}: TokenListProps) => {
  const balances = useSelector((state) => state.session.userTokenBalances);
  const hasBalances = useMemo(
    () => Object.keys(balances).length !== 0,
    [balances]
  );
  return list.length === 0 ? (
    <div className="flex flex-col items-center justify-center h-[250px]">
      <img src={notFound} width={80} height={80} alt="notFound" />
      <p className="text-base text-primary font-medium text-center mt-2">
        Token Not Found
      </p>
    </div>
  ) : (
    <div className="bd" id="scrollableDiv">
      <InfiniteScroll
        dataLength={list.length}
        next={() => setList(list)}
        hasMore={actualTokensListLength > list.length}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
        scrollThreshold={0.95}
        height={"35vh"}
      >
        {list.map((token: TokenInfo) => (
          <TokenDisplay
            key={token.address}
            {...token}
            showBalance={true}
            onClick={handleTokenSelection}
            hasBalances={hasBalances}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};

type TokenDisplayProps = TokenInfo & {
  showBalance?: boolean;
  onClick?: (token: TokenInfo) => void;
  style?: React.CSSProperties;
  hasBalances?: boolean;
};

export const TokenDisplay = ({
  style,
  onClick,
  showBalance,
  hasBalances,
  ...token
}: TokenDisplayProps) => {
  const [iconSrc, setIconSrc] = useState(token.logoURI);
  const { connected } = useWallet();
  const balances = useSelector((state) => state.session.userTokenBalances);

  return (
    <div
      className="hover-token flex justify-between items-center cursor-pointer"
      onClick={() => onClick?.(token)}
    >
      <div className="token p-2">
        <div className="token-logo">
          <img
            src={iconSrc}
            alt={`${token.name} Logo`}
            onError={() => setIconSrc(MeshIcon)}
          />
        </div>
        <div className="token-info">
          <div className="text-primary font-bold">{token.symbol}</div>
          <RedirectAddress address={token.address} />
        </div>
      </div>
      <div>
        {hasBalances && connected ? (
          <div className="flex flex-col items-end justify-end">
            <div className="text-sm text-primary mr-4">
              {balances[token.address]
                ? balances[token.address].balance
                : undefined}
            </div>
            <div className="text-primary mr-4">
              {balances[token.address]
                ? "$" + balances[token.address].amount?.toFixed(2)
                : undefined}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const TokenChip = ({
  token,
  onClick,
}: {
  token: TokenInfo;
  onClick?: (token: TokenInfo) => void;
}) => {
  return (
    <div
      className="flex flex-row items-center cursor-pointer border border-gray-700 p-1 md:p-2 bg-base-content rounded-md mr-2 mb-2 md:mr-0 md:mb-2"
      onClick={() => onClick?.(token)}
    >
      <div className="token-logo">
        <img src={token.logoURI} alt={`${token.name} Logo`} />
      </div>
      <div className=" text-sm md:text-base text-primary font-bold">
        {token.symbol}
      </div>
    </div>
  );
};
