import { ReactNode } from "react";
import { WalletName } from "@solana/wallet-adapter-base";
import { PublicKey } from "@solana/web3.js";

import { formatTokenAmount, generateExplorerTxLink } from "Utils/format";

import { conciseAddress } from "Utils/trunc";
import { TokenInfo } from "Types/tokens";

const BaseToast = ({
  heading,
  subHeading,
  explorerLink,
  txId,
}: {
  heading: string;
  subHeading?: string | ReactNode;
  explorerLink?: string;
  txId?: string;
}) => {
  return (
    <div>
      <p className="font-extrabold">{heading}</p>
      {subHeading ? (
        <p className="text-secondary text-sm">{subHeading}</p>
      ) : null}
      {txId ? (
        <p
          className="mt-2 text-sm mesh-text-gradient font-extrabold w-fit"
          onClick={() =>
            window.open(explorerLink ?? generateExplorerTxLink(txId), "_blank")
          }
          style={{ borderBottom: "1px solid #cd42ff" }}
        >
          View transaction
        </p>
      ) : null}
    </div>
  );
};

export const SwapSuccessToast = ({
  amountA,
  amountB,
  tokenA,
  tokenB,
  txId,
}: {
  amountA: string;
  amountB: string;
  tokenA: TokenInfo;
  tokenB: TokenInfo;
  txId: string;
}) => {
  return (
    <BaseToast
      heading="Swap Transaction Successful"
      subHeading={
        <>
          Successfully swapped{" "}
          <span className="font-semibold">
            {formatTokenAmount(+amountA)}{" "}
            <span className="mesh-text-gradient">${tokenA.symbol}</span>
          </span>{" "}
          to{" "}
          <span className="font-semibold">
            {formatTokenAmount(+amountB)}{" "}
            <span className="mesh-text-gradient">${tokenB.symbol}</span>
          </span>
        </>
      }
      txId={txId}
    />
  );
};

export const WrapUnwrapSuccessToast = ({
  amountA,
  txId,
  isWrap,
}: {
  amountA: string;
  txId: string;
  isWrap: boolean;
}) => {
  return (
    <BaseToast
      heading={`${isWrap ? "Wrap" : "Unwrap"} Transaction Successful`}
      subHeading={
        <>
          Successfully {isWrap ? "wrapped" : "unwrapped"}{" "}
          <span className="font-semibold">
            {formatTokenAmount(+amountA)}{" "}
            <span className="mesh-text-gradient">
              {isWrap ? "SOL" : "wSOL"}
            </span>
          </span>{" "}
        </>
      }
      txId={txId}
    />
  );
};

export const TxRejectedToast = () => {
  return (
    <BaseToast
      heading="Transaction Rejected"
      subHeading="Your transaction has been rejected."
    />
  );
};

export const TxCanceledToast = () => {
  return (
    <BaseToast
      heading="Transaction Canceled"
      subHeading="Your transaction has been canceled."
    />
  );
};

export const TxFailedToast = ({ txId }: { txId?: string }) => {
  return (
    <BaseToast
      heading="Transaction Failed"
      subHeading="Your transaction has failed."
      txId={txId}
    />
  );
};

export const TxSimulationFailToast = ({ txId }: { txId?: string }) => {
  return (
    <BaseToast
      heading="Transaction Simulation Failed"
      subHeading="Your transaction might get failed due to low slippage or other reasons."
      txId={txId}
    />
  );
};

export const SlippageExceededToast = ({ txId }: { txId?: string }) => {
  return (
    <BaseToast
      heading="Slippage tolerance exceeded"
      subHeading="Try again with more slippage."
      txId={txId}
    />
  );
};

export const TxProgressToast = () => {
  return (
    <BaseToast
      heading="Processing Transaction"
      subHeading="Transaction is in processing."
    />
  );
};

export const TxSignToast = () => {
  return (
    <BaseToast
      heading="Sign Transaction on Wallet"
      subHeading="Waiting for wallet to sign the transaction. "
    />
  );
};

export const WalletDiconnectedToast = ({
  walletName,
}: {
  walletName?: WalletName<string>;
}) => {
  return (
    <BaseToast
      heading={`${walletName ?? ""} Wallet Disconnected`}
      subHeading="Your wallet has been disconnected"
    />
  );
};

export const WalletConnectedToast = ({
  publicKey,
  walletName,
}: {
  publicKey: PublicKey;
  walletName?: WalletName<string>;
}) => {
  return (
    <BaseToast
      heading={`${walletName ?? ""} Wallet Connected`}
      subHeading={conciseAddress(publicKey.toBase58(), 6, 5)}
    />
  );
};
