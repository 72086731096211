import { QuoteGetSwapModeEnum } from "@jup-ag/api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MESH_TOKENINFO, NATIVE_SOL_TOKENINFO } from "Constants/tokens";
import { Percentage, SessionReducer } from "Types/reducers";
import {
  QouteResponse,
  token_selection_type,
  TokenInfo,
  TokenListsType,
  TokensCharts,
  TokensPrices,
  UserTokenBalances,
  UserTokens,
} from "Types/tokens";
import { checkIfWrapUnwrap } from "Utils/judger";

const initialState: SessionReducer = {
  userTokens: {},
  userTokenBalances: {},
  tokensListType: TokenListsType.strict,
  tokenA: NATIVE_SOL_TOKENINFO,
  tokenB: MESH_TOKENINFO,
  percentage: Percentage._0,
  amountA: "",
  initialAmountA: "",
  amountB: "",
  initialAmountB: "",
  swapMode: QuoteGetSwapModeEnum.ExactIn,
  userBalanceA: "0",
  userBalanceB: "0",
  selectedToken: token_selection_type.A,
  isTokenListOpen: false,
  qoute: undefined,
  isFirstQoute: true,
  successTxCount: 0,
  meshPrice: 0,
  isSwapSettingsOpen: false,
  swapErrors: [],
  swapWarnings: [],
  currentPrices: {},
  currentCharts: {},
  isSwapConfirmedModalOpen: false,
  userInitialPoints: 0,
  userUpdatedPoints: 0,
  isWrappingSOL: false,
  isUnwrappingSOL: false,
  isInSufficientBalance: false,
};

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    incrementSuccessTxCount(state) {
      state.successTxCount = state.successTxCount + 1;
    },
    setUserTokens(state, action: PayloadAction<UserTokens>) {
      state.userTokens = action.payload;
    },
    setIsInSufficientBalance(state, action: PayloadAction<boolean>) {
      state.isInSufficientBalance = action.payload;
    },
    setTokensListType(state, action: PayloadAction<TokenListsType>) {
      state.tokensListType = action.payload;
    },
    setTokenA(state, action: PayloadAction<TokenInfo>) {
      state.tokenA = action.payload;
    },
    setTokenB(state, action: PayloadAction<TokenInfo>) {
      state.tokenB = action.payload;
    },
    exchangeTokens(state) {
      const temp = state.tokenA;
      state.tokenA = state.tokenB;
      state.amountA = state.initialAmountB;
      state.amountB = state.initialAmountA;
      state.tokenB = temp;
      const initialTemp = state.initialAmountA;
      state.initialAmountA = state.initialAmountB;
      state.initialAmountB = initialTemp;
    },
    setPercentage(state, action: PayloadAction<Percentage>) {
      state.percentage = action.payload;
    },
    setAmountA(state, action: PayloadAction<string>) {
      state.amountA = action.payload;
      if (
        state.isFirstQoute ||
        checkIfWrapUnwrap(state.tokenA.address, state.tokenB.address)
          .isWrapUnwrapIntent
      )
        state.initialAmountA = action.payload;
    },
    setAmountB(state, action: PayloadAction<string>) {
      state.amountB = action.payload;
      if (
        state.isFirstQoute ||
        checkIfWrapUnwrap(state.tokenA.address, state.tokenB.address)
          .isWrapUnwrapIntent
      )
        state.initialAmountB = action.payload;
    },
    setSwapMode(state, action: PayloadAction<QuoteGetSwapModeEnum>) {
      if (state.swapMode !== action.payload) state.swapMode = action.payload;
    },
    setUserBalanceA(state, action: PayloadAction<string>) {
      state.userBalanceA = action.payload;
    },
    setUserBalanceB(state, action: PayloadAction<string>) {
      state.userBalanceB = action.payload;
    },
    setSelectedToken(state, action: PayloadAction<token_selection_type>) {
      state.selectedToken = action.payload;
    },
    setIsTokenListOpen(state, action: PayloadAction<boolean>) {
      state.isTokenListOpen = action.payload;
    },
    setQoute(state, action: PayloadAction<QouteResponse | undefined>) {
      state.qoute = action.payload;
    },
    setIsFirstQoute(state, action: PayloadAction<boolean>) {
      state.isFirstQoute = action.payload;
    },
    resetAmounts(state) {
      state.amountB = "";
      state.amountA = "";
      state.initialAmountA = "";
      state.initialAmountB = "";
    },
    setMeshPrice(state, action: PayloadAction<number>) {
      state.meshPrice = action.payload;
    },
    setUserTokenBalances(state, action: PayloadAction<UserTokenBalances>) {
      state.userTokenBalances = action.payload;
    },
    setIsSwapSettingsOpen(state, action: PayloadAction<boolean>) {
      state.isSwapSettingsOpen = action.payload;
    },
    setSwapError(state, action: PayloadAction<string[]>) {
      state.swapErrors = action.payload;
    },
    setSwapWarnings(state, action: PayloadAction<string[]>) {
      state.swapWarnings = action.payload;
    },
    setCurrentPrices(state, action: PayloadAction<TokensPrices>) {
      state.currentPrices = action.payload;
    },
    setCurrentCharts(state, action: PayloadAction<TokensCharts>) {
      state.currentCharts = action.payload;
    },
    setIsSwapConfirmModalOpen(state, action: PayloadAction<boolean>) {
      state.isSwapConfirmedModalOpen = action.payload;
    },
    setUserInitialPoints(state, action: PayloadAction<number>) {
      state.userInitialPoints = action.payload;
    },
    setUserUpdatedPoints(state, action: PayloadAction<number>) {
      state.userUpdatedPoints = action.payload;
    },
  },
});

export default session.reducer;
export const {
  setIsInSufficientBalance,
  setSwapWarnings,
  setCurrentCharts,
  setCurrentPrices,
  setSwapError,
  setIsSwapSettingsOpen,
  setUserUpdatedPoints,
  setIsSwapConfirmModalOpen,
  setUserInitialPoints,
  resetAmounts,
  incrementSuccessTxCount,
  exchangeTokens,
  setIsFirstQoute,
  setMeshPrice,
  setUserTokenBalances,
  setQoute,
  setIsTokenListOpen,
  setSelectedToken,
  setSwapMode,
  setUserTokens,
  setTokensListType,
  setTokenA,
  setTokenB,
  setPercentage,
  setAmountA,
  setAmountB,
  setUserBalanceA,
  setUserBalanceB,
} = session.actions;
