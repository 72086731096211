import { useEffect } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { formatUnits } from "ethers";

import { dispatch, store, useSelector } from "Store";
import PriceManagerClass from "Classes/priceManager";
import { checkIfWrapUnwrap, checkIfwSOLAddress } from "Utils/judger";
import ChartManagerClass from "Classes/chartManager";
import { updateAutoPriorityFee } from "Store/Reducers/app";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { PriorityLevel } from "Types/reducers";
import { NATIVE_SOL_TOKENINFO } from "Constants/tokens";
import { Jupiter } from "Classes/jupiter";
import { QuoteGetSwapModeEnum } from "@jup-ag/api";
import CachedService from "Classes/cachedService";
import {
  setIsChartLoading,
  setIsPricingLoading,
} from "Store/Reducers/loadings";
import {
  setAmountA,
  setAmountB,
  setCurrentCharts,
  setCurrentPrices,
  setIsFirstQoute,
  setIsInSufficientBalance,
  setMeshPrice,
  setSwapError,
  setSwapWarnings,
  setUserBalanceA,
  setUserBalanceB,
  setUserInitialPoints,
} from "Store/Reducers/session";
import { fetchMeshPrice } from "Utils/helpers";
import {
  fetchLatestPriorityFee,
  fetchPricesOfUserTokens,
  fetchRoutePlan,
  fetchUserScore,
  fetchUserTokens,
} from "Utils/fetchers";
import { NATIVE_SOL, minimumSOLbalance } from "Constants/misc";

const Listeners = () => {
  const { connection } = useConnection();
  const { publicKey, connected } = useWallet();

  const amountA = useSelector((state) => state.session.amountA);
  const tokenA = useSelector((state) => state.session.tokenA);
  const tokenB = useSelector((state) => state.session.tokenB);
  const successTxCount = useSelector((state) => state.session.successTxCount);
  const userTokens = useSelector((state) => state.session.userTokens);
  const priorityLevel = useSelector((state) => state.app.priorityLevel);
  const balanceLoading = useSelector((state) => state.loadings.balanceLoading);
  const slippage = useSelector((state) => state.app.slippage);
  const versionedTx = useSelector((state) => state.app.versionedTx);
  const onlyDirectRoute = useSelector((state) => state.app.onlyDirectRoute);
  const userBalanceA = useSelector((state) => state.session.userBalanceA);
  const qoute = useSelector((state) => state.session.qoute);
  const swapMode = useSelector((state) => state.session.swapMode);
  const isTxInProgress = useSelector((state) => state.loadings.isTxInProgress);
  const isInSufficientBalance = useSelector(
    (state) => state.session.isInSufficientBalance
  );
  const isSwapConfirmedModal = useSelector(
    (state) => state.session.isSwapConfirmedModalOpen
  );

  useEffect(() => {
    (async () => {
      dispatch(setIsPricingLoading(true));
      const price = await fetchMeshPrice();
      if (price) {
        dispatch(setMeshPrice(price));
      }
      dispatch(setIsPricingLoading(false));
    })();
  }, []);

  // fetch user tokens balances
  useEffect(() => {
    (async () => {
      if (publicKey) {
        const freshUserTokens = await fetchUserTokens(connection, publicKey);
        fetchPricesOfUserTokens(freshUserTokens);
      }
    })();
  }, [connection, publicKey, successTxCount]);

  // fetch user leaderboard score
  useEffect(() => {
    if (!isSwapConfirmedModal) {
      (async () => {
        const score = await fetchUserScore(publicKey);
        dispatch(setUserInitialPoints(score));
      })();
    }
  }, [publicKey, successTxCount, isSwapConfirmedModal]);

  // price data of newly selected tokens
  useEffect(() => {
    (async () => {
      dispatch(setIsPricingLoading(true));
      const prices = await new PriceManagerClass([
        checkIfwSOLAddress(tokenA.address),
        checkIfwSOLAddress(tokenB.address),
      ]).fetchTokensPrices();
      dispatch(setCurrentPrices(prices));
      dispatch(setIsPricingLoading(false));
    })();
  }, [tokenA.address, tokenB.address]);

  // chart data
  useEffect(() => {
    let timeoutId: any;

    const fetchData = async () => {
      const charts = await new ChartManagerClass([
        checkIfwSOLAddress(tokenA.address),
        checkIfwSOLAddress(tokenB.address),
      ]).fetchCharts();
      store.dispatch(setCurrentCharts(charts));
      store.dispatch(setIsChartLoading(false));
    };

    const delayedFetch = () => {
      timeoutId = setTimeout(fetchData, 4000);
    };

    store.dispatch(setIsChartLoading(true));
    delayedFetch();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [tokenA.address, tokenB.address]);

  // auto priority fee fetching interval
  useEffect(() => {
    let intervalId: any;

    const fetchData = async () => {
      const fee = await fetchLatestPriorityFee();
      // Dispatch action to store fee in Redux state or do whatever you want with it
      // For example: dispatch({ type: 'SET_PRIORITY_FEE', payload: fee });
      if (fee) dispatch(updateAutoPriorityFee(fee / LAMPORTS_PER_SOL));
    };

    const startFetching = () => {
      fetchData(); // Fetch data immediately when starting
      intervalId = setInterval(fetchData, 0.5 * 60 * 1000); // Fetch data every 2 minutes
    };

    const stopFetching = () => {
      clearInterval(intervalId);
    };

    if (priorityLevel === PriorityLevel.AUTO) {
      startFetching();
    } else {
      stopFetching();
    }

    return () => {
      stopFetching(); // Clear interval when component unmounts or when priorityLevel changes
    };
  }, [priorityLevel]);

  // warning about low solana balance
  useEffect(() => {
    let solBalance = userTokens[NATIVE_SOL_TOKENINFO.address]?.uiAmount ?? 0;
    // make sure wallet is connected and balance is not loading and then check if solana balance lies within specified range
    if (!balanceLoading && connected && solBalance >= 0 && solBalance < 0.01) {
      dispatch(
        setSwapWarnings([
          "To cover fees and deposits, you'll need at least 0.01 SOL.",
        ])
      );
    } else {
      store.dispatch(setSwapWarnings([]));
    }
  }, [tokenA.address, userTokens, balanceLoading, connected]);

  // fetch new qoute on swap setting change
  useEffect(() => {
    dispatch(setIsFirstQoute(false));
    fetchRoutePlan(new Jupiter());
  }, [slippage, connection, versionedTx, onlyDirectRoute]);

  // update the qoute amount in input field
  useEffect(() => {
    const { isWrapUnwrapIntent } = checkIfWrapUnwrap(
      tokenA.address,
      tokenB.address
    );
    if (!isWrapUnwrapIntent) {
      if (swapMode === QuoteGetSwapModeEnum.ExactIn) {
        dispatch(
          setAmountB(
            qoute?.outAmount
              ? formatUnits(qoute.outAmount, tokenB.decimals)
              : ""
          )
        );
      } else if (swapMode === QuoteGetSwapModeEnum.ExactOut) {
        dispatch(
          setAmountA(
            qoute?.inAmount ? formatUnits(qoute.inAmount, tokenA.decimals) : ""
          )
        );
      }
    }
  }, [
    qoute,
    swapMode,
    tokenA.decimals,
    tokenB.decimals,
    tokenA.address,
    tokenB.address,
  ]);

  // update the balances amount with the balances of current selected tokens
  useEffect(() => {
    dispatch(
      setUserBalanceA(userTokens[tokenA.address]?.uiAmountString ?? "0")
    );
    dispatch(
      setUserBalanceB(userTokens[tokenB.address]?.uiAmountString ?? "0")
    );
  }, [tokenA.address, tokenB.address, userTokens]);

  // stop auto qoutation if transaction is in progress
  useEffect(() => {
    if (isTxInProgress) {
      CachedService.stopQouteTimer();
    }
  }, [isTxInProgress]);

  // warning on low sol balance
  useEffect(() => {
    if (tokenA.address === NATIVE_SOL && isInSufficientBalance) {
      dispatch(
        setSwapError([
          `You need at least ${minimumSOLbalance} SOL to pay for fees and deposits`,
        ])
      );
    } else {
      dispatch(setSwapError([]));
    }
  }, [isInSufficientBalance, tokenA.address]);

  // insufficient balance
  useEffect(() => {
    dispatch(
      setIsInSufficientBalance(
        amountA
          ? tokenA.address === NATIVE_SOL_TOKENINFO.address
            ? +userBalanceA - +amountA < minimumSOLbalance
            : +amountA > +userBalanceA
          : false
      )
    );
  }, [amountA, tokenA.address, userBalanceA]);

  return <></>;
};

export default Listeners;
