import { useMemo } from "react";

import WhiteCheckIcon from "Assets/Svgs/white-check.svg";
import { TwBreakPoints } from "Types/misc";
import { useTwBreakpoints } from "hooks/useTwBreakpoints";
import { dispatch, useSelector } from "Store";
import { setIsSwapConfirmModalOpen } from "Store/Reducers/session";
import CachedService from "Classes/cachedService";
import { formatTokenAmount } from "Utils/format";

export const SwapConfirmedModal = () => {
  const isModalOpen = useSelector(
    (state) => state.session.isSwapConfirmedModalOpen
  );
  const tokenA = useSelector((state) => state.session.tokenA);
  const tokenB = useSelector((state) => state.session.tokenB);
  const userInitialPoints = useSelector(
    (state) => state.session.userInitialPoints
  );
  const userUpdatedPoints = useSelector(
    (state) => state.session.userUpdatedPoints
  );
  const tokenAmountA = CachedService.tokenAmountA;
  const tokenAmountB = CachedService.tokenAmountB;
  const screenSize = useTwBreakpoints();

  const closeModal = () => {
    dispatch(setIsSwapConfirmModalOpen(false));
    CachedService.tokenAmountA = "";
    CachedService.tokenAmountB = "";
  };

  const handleViewLeaderboard = () => {
    closeModal();
    CachedService.navigation("/leaderboard");
  };
  const modalScale = useMemo(
    () =>
      screenSize === TwBreakPoints.sm || screenSize === TwBreakPoints.xs
        ? {}
        : { scale: "0.9" },
    [screenSize]
  );
  return (
    <dialog
      id="my_modal_3"
      className="modal"
      style={isModalOpen ? { backdropFilter: "blur(10px)" } : {}}
      open={isModalOpen}
      onClose={closeModal}
    >
      <div
        className="modal-box gradient-border !p-[0.125rem] !rounded-[0.25rem]"
        style={modalScale}
      >
        <div className="bg-base-content !rounded-[0.25rem] p-6">
          <div className="flex flex-col justify-center items-center">
            <img src={WhiteCheckIcon} alt="WhiteCheckIcon" />
            <div className="text-2xl font-semibold my-3">Swap Confirmed</div>
            <p className="text-sm text-secondary">
              Congratulation you have successfully swapped{" "}
              <span
                className="text-primary cursor-pointer"
                title={tokenAmountA}
              >
                {formatTokenAmount(+tokenAmountA)} {tokenA.symbol}
              </span>{" "}
              into&nbsp;
              <span
                className="text-primary cursor-pointer"
                title={tokenAmountB}
              >
                {formatTokenAmount(+tokenAmountB)} {tokenB.symbol}
              </span>
              &nbsp;and you have obtained&nbsp;
              <span className="mesh-text-gradient w-fit">
                {(userUpdatedPoints - userInitialPoints).toFixed(2)} points
              </span>
            </p>
            <div
              className="mesh-text-gradient w-fit font-semibold text-base mt-3 cursor-pointer"
              onClick={handleViewLeaderboard}
            >
              View Leaderboard
            </div>
            {/* <div className="text-secondary mt-4 text-center">
              Leaderboard Coming Soon!
            </div> */}
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
