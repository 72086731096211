import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QUICKNODE_MAINNET_RPC } from "Constants/endpoints";
import { solanaExplorerUrl } from "Constants/misc";

import {
  AppReducer,
  PriorityLevel,
  PriorityMode,
  SlippageLevels,
  SlippageValues,
} from "Types/reducers";

const initialState: AppReducer = {
  lastTokensUpdated: Date.now(),
  slippage: {
    low: 0.5,
    medium: 1,
    high: 2,
    custom: "",
  },
  currentSlippage: SlippageLevels.medium,
  onlyDirectRoute: false,
  versionedTx: true,
  useWSOL: false,
  currentExplorer: solanaExplorerUrl,
  currentRPC: QUICKNODE_MAINNET_RPC,
  isCustomRPC: false,
  priorityLevel: PriorityLevel.AUTO,
  priorityMode: PriorityMode.MAX_CAP,
  maxCap: 0.005,
  exactFee: undefined,
  PriorityFeeLevelsValues: {
    [PriorityLevel.AUTO]: 0.00003,
    [PriorityLevel.FAST]: 0.00003,
    [PriorityLevel.TURBO]: 0.0003,
    [PriorityLevel.ULTRA]: 0.003,
  },
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLastTokensUpdated(state, action) {
      state.lastTokensUpdated = action.payload;
    },
    setSlippage(
      state,
      action: PayloadAction<{
        level: SlippageLevels;
        custom: string;
      }>
    ) {
      state.currentSlippage = action.payload.level;
      state.slippage = { ...state.slippage, custom: action.payload.custom };
    },
    toggleOnlyDirectRoute(state) {
      state.onlyDirectRoute = !state.onlyDirectRoute;
    },
    toggleVersionedTx(state) {
      state.versionedTx = !state.versionedTx;
    },
    toggleWSOL(state, action: PayloadAction<boolean>) {
      state.useWSOL = action.payload;
    },
    resetSwapSettings(state) {
      state.versionedTx = initialState.versionedTx;
      state.onlyDirectRoute = initialState.onlyDirectRoute;
      state.slippage = initialState.slippage;
      state.currentSlippage = initialState.currentSlippage;
    },
    saveSwapSettings(
      state,
      action: PayloadAction<{
        versionedTx: boolean;
        onlyDirectRoute: boolean;
        currentSlippage: SlippageLevels;
        slippage: SlippageValues;
      }>
    ) {
      state.versionedTx = action.payload.versionedTx;
      state.onlyDirectRoute = action.payload.onlyDirectRoute;
      state.slippage = action.payload.slippage;
      state.currentSlippage = action.payload.currentSlippage;
    },
    setCurrentExplorer(state, action: PayloadAction<string>) {
      state.currentExplorer = action.payload;
    },
    setCurrentRPC(state, action: PayloadAction<string>) {
      state.currentRPC = action.payload;
    },
    setCustomRPC(state, action: PayloadAction<boolean>) {
      state.isCustomRPC = action.payload;
    },
    setPriorityLevel(state, action: PayloadAction<PriorityLevel>) {
      state.priorityLevel = action.payload;
    },
    setPriorityMode(state, action: PayloadAction<PriorityMode>) {
      state.priorityMode = action.payload;
    },
    setMaxCap(state, action: PayloadAction<number | undefined>) {
      state.maxCap = action.payload;
    },
    setExactFee(state, action: PayloadAction<number | undefined>) {
      state.exactFee = action.payload;
    },
    updateAutoPriorityFee(state, action: PayloadAction<number>) {
      state.PriorityFeeLevelsValues = {
        ...state.PriorityFeeLevelsValues,
        [PriorityLevel.AUTO]: action.payload,
      };
    },
    updatePriorityOptions(
      state,
      action: PayloadAction<{
        mode: PriorityMode;
        level: PriorityLevel;
        customInput: number | undefined;
      }>
    ) {
      state.priorityMode = action.payload.mode;
      state.priorityLevel = action.payload.level;
      if (action.payload.mode === PriorityMode.EXACT_FEE) {
        state.exactFee = action.payload.customInput;
      } else if (action.payload.mode === PriorityMode.MAX_CAP) {
        state.maxCap = action.payload.customInput;
      }
    },
  },
});

export default app.reducer;

export const {
  toggleWSOL,
  updateAutoPriorityFee,
  updatePriorityOptions,
  setExactFee,
  saveSwapSettings,
  resetSwapSettings,
  setLastTokensUpdated,
  setSlippage,
  toggleOnlyDirectRoute,
  toggleVersionedTx,
  setCurrentExplorer,
  setCurrentRPC,
  setCustomRPC,
  setPriorityLevel,
  setPriorityMode,
  setMaxCap,
} = app.actions;
