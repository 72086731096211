import { PriorityMode } from "Types/reducers";
import React, { useState } from "react";

const CommonClass =
  "tab text-secondary font-semibold h-[unset] bg-base-100 border-[5px] border-base-100";

export const SwitchTabs = ({
  currentTab,
  tabs,
  onChange,
}: {
  currentTab: PriorityMode;
  tabs: { text: PriorityMode }[];
  onChange: (tabId: PriorityMode) => void;
}) => {
  const [activeTab, setActiveTab] = useState(currentTab ?? tabs[0].text);

  const changeTab = (tabId: PriorityMode) => {
    setActiveTab(tabId);
    onChange(tabId);
  };

  return (
    <div className="flex justify-center rounded">
      {tabs.map((tab) => (
        <div
          key={tab.text}
          className={`${CommonClass} ${
            activeTab === tab.text
              ? "bg-base-content mesh-text-gradient w-fit"
              : ""
          }`}
          onClick={() => changeTab(tab.text)}
        >
          <p
            className={` ${
              activeTab === tab.text
                ? "tab-active mesh-text-gradient w-fit"
                : ""
            }`}
          >
            {tab.text}
          </p>
        </div>
      ))}
    </div>
  );
};
