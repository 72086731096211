import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const tokenList = createSlice({
  name: "tokensList",
  initialState,
  reducers: {},
});

export default tokenList.reducer;
// export const { } = tokenList.actions;
