import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LoadingsReducer } from "Types/reducers";

const initialState: LoadingsReducer = {
  tokensLoading: false,
  tokensFetching: false,
  qouteLoading: false,
  balanceLoading: false,
  isTxInProgress: false,
  isPriceLoading: false,
  isApprovalPending: false,
  isChartLoading: false,
};

const loadings = createSlice({
  name: "loadings",
  initialState,
  reducers: {
    setTokensLoading(state, action: PayloadAction<boolean>) {
      state.tokensLoading = action.payload;
    },
    setIsApprovalPending(state, action: PayloadAction<boolean>) {
      state.isApprovalPending = action.payload;
    },
    setQouteLoading(state, action: PayloadAction<boolean>) {
      state.qouteLoading = action.payload;
    },
    setBalanceLoading(state, action: PayloadAction<boolean>) {
      state.balanceLoading = action.payload;
    },
    setTokensFetching(state, action: PayloadAction<boolean>) {
      state.tokensLoading = action.payload;
      state.tokensFetching = action.payload;
    },
    setIsTxInProgress(state, action: PayloadAction<boolean>) {
      state.isTxInProgress = action.payload;
    },
    setIsPricingLoading(state, action: PayloadAction<boolean>) {
      state.isPriceLoading = action.payload;
    },
    setIsChartLoading(state, action: PayloadAction<boolean>) {
      state.isChartLoading = action.payload;
    },
  },
});

export default loadings.reducer;
export const {
  setIsTxInProgress,
  setTokensLoading,
  setTokensFetching,
  setQouteLoading,
  setBalanceLoading,
  setIsPricingLoading,
  setIsApprovalPending,
  setIsChartLoading,
} = loadings.actions;
