import { QuoteResponse as JupQuoteResponse } from "@jup-ag/api";

import { ChartData } from "./misc";

export type TokenInfo = {
  address: string;
  chainId: number;
  decimals: number;
  name: string;
  symbol: string;
  logoURI: string;
  tags: string[];
  extensions?: { coingeckoId?: string };
  weight?: number;
  balance?: number;
};

export type UnNamedTokenInfo = {
  decimals: number;
  extensions: {
    coingeckoId: string;
  };
  hasFreeze: number;
  mint: string;
};

export type JupiterTokenApiRes = TokenInfo[];

export type RaydiumTokenApiRes = {
  blacklist: unknown[];
  name: string;
  official: TokenInfo[];
  unOfficial: TokenInfo[];
  unNamed: UnNamedTokenInfo[];
};

export enum token_selection_type {
  A = "A",
  B = "B",
}

export type ParsedTokenAmount = {
  amount: string;
  decimals: number;
  uiAmount: number;
  uiAmountString: string;
};

export type ParsedTokenInfo = {
  isNative: boolean;
  mint: string;
  owner: string;
  state: string;
  tokenAmount: ParsedTokenAmount;
};

export type UserTokens = Record<string, ParsedTokenAmount>;

export enum TokenListsType {
  strict = "strictTokenList",
  all = "allTokenList",
}

export type JupiterPricingResponse = {
  data: {
    [symbol: string]: {
      id: string;
      mintSymbol: string;
      vsToken: string;
      vsTokenSymbol: string;
      price: number;
    };
  };
  timeTaken: number;
};
export type QouteResponse = JupQuoteResponse;

export type UserTokenBalances = Record<
  string,
  { balance: number | undefined; amount: number }
>;

export type TokensPrices = Record<string, TokenPriceData>;
export type TokensCharts = Record<string, ChartData>;

export type TokenPriceData = {
  price: number;
  priceChange: number | null;
};
