import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";

import Header from "./header";
import Footer from "./footer";
import TokenModal from "Components/Modals/tokenList";
import SwapSettingsModal from "Components/Modals/swapSettings";
import { SwapConfirmedModal } from "Components/Modals/swapConfirmed";
import Listeners from "Components/listeners";

const MainLayout: FunctionComponent = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <TokenModal />
      <SwapSettingsModal />
      <SwapConfirmedModal />
      <Listeners />
    </>
  );
};

export default MainLayout;
