import solanaLogo from "Assets/Svgs/solana.svg";

export const Chain = () => {
  return (
    <div className="hidden mr-4 sm:mr-4 px-3 py-2 md:flex items-center bg-base-content rounded">
      <img src={solanaLogo} alt="ringMeshIcon" width={25} height={25} />
      <p className="text-primary font-extrabold px-1">Solana</p>
    </div>
  );
};
