import localforage from "localforage";
import axios from "axios";

import { store } from "Store";
import { setLastTokensUpdated } from "Store/Reducers/app";
import { JupiterTokenApiRes, TokenInfo, TokenListsType } from "Types/tokens";
import { JUPITER_ALL_LIST, JUPITER_STRICT_LIST } from "Constants/endpoints";
import { setTokensFetching, setTokensLoading } from "Store/Reducers/loadings";
import { MESH_TOKENINFO, WSOL_TOKENINFO } from "Constants/tokens";

class TokenCache {
  strictTokens: TokenInfo[] = [];
  allTokens: TokenInfo[] = [];

  //   constructor() {
  //     this.loadTokensList();
  //   }

  loadTokensList = async () => {
    store.dispatch(setTokensLoading(true));
    const strictTokens = await this.getTokensList(TokenListsType.strict);
    const allTokens = await this.getTokensList(TokenListsType.all);
    if (strictTokens) {
      this.strictTokens = strictTokens;
    }
    if (allTokens) {
      this.allTokens = allTokens;
    }
    store.dispatch(setTokensLoading(false));
  };

  getTokensList = async (listType: TokenListsType) => {
    try {
      return (await localforage.getItem(listType)) as TokenInfo[] | null;
    } catch (error) {
      console.error("Error fetching tokensList from localforage:", error);
      return null;
    }
  };

  setTokensList = async (listType: TokenListsType, tokenList: TokenInfo[]) => {
    try {
      await localforage.setItem(listType, tokenList);
      store.dispatch(setLastTokensUpdated(Date.now()));
    } catch (error) {
      console.error("Error storing tokensList:", error);
      return null;
    }
  };

  fetchTokensList = async (listType: TokenListsType) => {
    try {
      store.dispatch(setTokensFetching(true));
      const tokensRes = await axios.get<JupiterTokenApiRes>(
        listType === TokenListsType.strict
          ? JUPITER_STRICT_LIST
          : JUPITER_ALL_LIST
      );

      if (tokensRes.status === 200) {
        if (listType === TokenListsType.strict) {
          tokensRes.data.push(MESH_TOKENINFO);
        }
        tokensRes.data.push(WSOL_TOKENINFO);
        this.setTokensList(listType, tokensRes.data);
        store.dispatch(setTokensFetching(false));
      }
    } catch (error) {
      console.log("Error fetching list", error);
    }
  };
}

const TokenCacheService = new TokenCache();

export default TokenCacheService;
