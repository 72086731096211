import { combineReducers } from "redux";
import { createMigrate, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createAction } from "@reduxjs/toolkit";

import app from "./app";
import tokensList from "./tokensList";
import session from "./session";
import loadings from "./loadings";
import { solanaExplorerUrl } from "Constants/misc";
import { QUICKNODE_MAINNET_RPC } from "Constants/endpoints";
import { PriorityLevel, PriorityMode, SlippageLevels } from "Types/reducers";

const persistConfig = {
  version: 5,
  key: "mesh-ts",
  storage,
  whitelist: ["app"],
  migrate: createMigrate({
    2: (state: any) => ({
      ...state,
      app: {
        ...state.app,
        slippage: {
          low: 0.5,
          medium: 1,
          high: 2,
          custom: "",
        },
        currentSlippage: SlippageLevels.medium,
        onlyDirectRoute: false,
        versionedTx: true,
        currentExplorer: solanaExplorerUrl,
        currentRPC: QUICKNODE_MAINNET_RPC,
        isCustomRPC: false,
      },
    }),
    5: (state: any) => ({
      ...state,
      app: {
        ...state.app,
        priorityLevel: PriorityLevel.FAST,
        priorityMode: PriorityMode.MAX_CAP,
        maxCap: 0,
      },
    }),
  }),
};

export const logout = createAction("USER_LOGOUT");

const reducers = combineReducers({
  app,
  tokensList,
  session,
  loadings,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    localStorage.removeItem("persist:mesh-ts");
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
