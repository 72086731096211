import { createUmi } from "@metaplex-foundation/umi-bundle-defaults";
import { mplTokenMetadata } from "@metaplex-foundation/mpl-token-metadata";
import {
  findMetadataPda,
  safeFetchMetadata,
} from "@metaplex-foundation/mpl-token-metadata";
import { fromWeb3JsPublicKey } from "@metaplex-foundation/umi-web3js-adapters";
import { unpackMint } from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";

import { ALCHEMY_RPC } from "Constants/endpoints";
import { TokenInfo } from "Types/tokens";
import { TOKENLIST_EXPIRE_PERIOD } from "Constants/misc";
import MeshIcon from "Assets/Svgs/ring-mesh.svg";
import TokenCacheService from "Classes/tokenCache";
import { store } from "Store";
import {
  exchangeTokens,
  setAmountA,
  setAmountB,
  setIsFirstQoute,
  setPercentage,
} from "Store/Reducers/session";
import { setQouteLoading } from "Store/Reducers/loadings";
import { fetchRoutePlan } from "./fetchers";
import { Jupiter } from "Classes/jupiter";
import { WSOL_TOKENINFO } from "Constants/tokens";
import { Percentage } from "Types/reducers";

export const shouldFetchTokenList = async (lastTokensUpdated: number) => {
  const currentTime = Date.now();
  const tokensListIsEmpty =
    TokenCacheService.allTokens.length === 0 ||
    TokenCacheService.strictTokens.length === 0;
  // Check if the current time is greater than or equal to 24 hours after lastTokensUpdated
  // or if tokens does not exist in redux
  return (
    currentTime - lastTokensUpdated >= TOKENLIST_EXPIRE_PERIOD ||
    tokensListIsEmpty
  );
};

export const fetchOnChainToken = async (
  tokenAddress: string,
  connection: Connection
): Promise<TokenInfo | undefined> => {
  try {
    const umi = createUmi(ALCHEMY_RPC).use(mplTokenMetadata());
    const token = new PublicKey(tokenAddress);
    const metadataPDA = findMetadataPda(umi, {
      mint: fromWeb3JsPublicKey(token),
    });
    const metadata = await safeFetchMetadata(umi, metadataPDA);
    const tokenInfo = await connection.getAccountInfo(
      new PublicKey(tokenAddress),
      "confirmed"
    );
    const Mint = unpackMint(new PublicKey(tokenAddress), tokenInfo);

    if (metadata && Mint) {
      return {
        address: metadata.mint,
        chainId: 101,
        decimals: Mint.decimals,
        name: metadata.name,
        symbol: metadata.symbol,
        logoURI: MeshIcon,
        tags: ["unknown"],
      };
    }
  } catch (error) {
    console.log("Error Fetching on-chain token info");
    return undefined;
  }
};

export const handleTokenExchange = (
  jupiter: Jupiter,
  isWrapUnwrapIntent: boolean = false
) => {
  const rotateImage = () => {
    const image = document.getElementById("exchangeIcon") as HTMLImageElement;
    if (image.style.transform === "rotate(180deg)") {
      image.style.transform = "rotate(0deg)";
    } else {
      image.style.transform = "rotate(180deg)";
    }
  };
  setTimeout(() => {
    store.dispatch(setIsFirstQoute(false));
    !isWrapUnwrapIntent && store.dispatch(setQouteLoading(true));
    store.dispatch(exchangeTokens());
    !isWrapUnwrapIntent && fetchRoutePlan(jupiter);
  }, 0);
  rotateImage();
};

export const forceMaxBalanceOfwSol = () => {
  const {
    session: { userTokens },
  } = store.getState();
  let wsolBalance = userTokens?.[WSOL_TOKENINFO.address]?.uiAmountString ?? "";
  store.dispatch(setAmountA(wsolBalance));
  store.dispatch(setAmountB(wsolBalance));
  store.dispatch(setPercentage(Percentage._100));
};
