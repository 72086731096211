import LeaderBoardTable from "Components/leaderboardTable";

// import { ComingSoon } from "Components/comingSoon";

const LeaderBoard = () => {
  return (
    <div className="flex items-center justify-center">
      <LeaderBoardTable />
      {/* <ComingSoon /> */}
    </div>
  );
};

export default LeaderBoard;
