import MeshLogoMobile from "Assets/Svgs/mesh.svg";
import { WalletButton } from "App";
import {
  MESH_STAKING_URL,
  navbarLinks,
  WALLET_BTN_LABELS,
} from "Constants/misc";
import { TokenPrice } from "Components/tokenPrice";
import { Chain } from "Components/chainBox";
import { useLocation } from "react-router-dom";
import { DAppSettings } from "Components/Modals/dAppSettings";
import CachedService from "Classes/cachedService";
import mixpanel from "mixpanel-browser";
import { ANALYTICS_EVENT } from "Constants/analytics";
import { useWallet } from "@solana/wallet-adapter-react";
import { useCallback } from "react";
import { doesWebsiteMatch } from "Utils/judger";

const Header = () => {
  const { pathname } = useLocation();
  const { publicKey } = useWallet();

  const renderLinks = useCallback(
    () =>
      navbarLinks.map((link) => {
        const doesMatch = doesWebsiteMatch(link.url);
        return (
          <div
            className={"text-sm font-medium accent-on-hover cursor-pointer ".concat(
              doesMatch ? "text-accent" : "text-primary"
            )}
            onClick={() => {
              if (link.url === MESH_STAKING_URL) {
                mixpanel.track(ANALYTICS_EVENT.CLICKED_ON_STAKING_DAPP, {
                  walletAddress: publicKey?.toBase58(),
                });
              }
              doesMatch
                ? CachedService.navigation("/")
                : window.open(link.url, "_blank");
            }}
          >
            {link.name}
          </div>
        );
      }),
    [publicKey]
  );
  return (
    <div>
      <div className="flex flex-row justify-between items-center bg-black w-full p-4 px-0 sm:px-14">
        <div className="flex gap-12 items-center">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={() => CachedService.navigation("/swap")}
          >
            <img src={MeshLogoMobile} alt="mesh-logo" width={45} height={45} />
          </div>
          <div className="hidden md:flex justify-between items-center w-80">
            {renderLinks()}
            <div
              onClick={() => CachedService.navigation("/leaderboard")}
              className={`text-sm font-medium accent-on-hover cursor-pointer `.concat(
                pathname.includes("/leaderboard")
                  ? "text-accent"
                  : "text-primary"
              )}
            >
              Leaderboard
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center">
          <TokenPrice />
          <Chain />
          <DAppSettings />
          <div className="hidden md:block">
            <WalletButton
              labels={WALLET_BTN_LABELS}
              style={{ borderRadius: "4px", fontFamily: "inter" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
