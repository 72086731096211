import { FunctionComponent } from "react";

import XLogo from "Assets/Svgs/x.svg";
import TelegramLogo from "Assets/Svgs/telegram.svg";
import GitbookLogo from "Assets/Svgs/gitbook.svg";
import MeshWhite from "Assets/Svgs/mesh-white.svg";
import MediumLogo from "Assets/Svgs/medium.svg";
import {
  TELEGRAM_LINK,
  GITBOOK,
  X_HANDLE,
  MESH_WEB,
  MESH_MEDIUM_URL,
} from "Constants/misc";

const Footer: FunctionComponent = () => {
  return (
    <div className="relative bottom-10 md:bottom-0 w-full p-2 mt-5 md:mt-2">
      <div className="flex justify-center p-0 lg:px-8 w-[97vw]">
        <div className="flex flex-row justify-evenly md:justify-between items-center w-1/2 lg:w-1/6 px-1 md:px-2 py-4">
          <a href={TELEGRAM_LINK} target="blank" className="cursor-pointer">
            <img
              src={TelegramLogo}
              width={20}
              height={20}
              alt="telegram-icon"
            />
          </a>
          {/* <a href={`mailto:${EMAIL}`} target="blank" className="cursor-pointer">
            <img src={MailLogo} width={20} height={20} alt="mail-icon" />
          </a> */}
          <a href={MESH_MEDIUM_URL} target="blank" className="cursor-pointer">
            <img src={MediumLogo} width={20} height={20} alt="mail-icon" />
          </a>
          <a href={X_HANDLE} target="blank" className="cursor-pointer">
            <img src={XLogo} width={20} height={20} alt="x-icon" />
          </a>
          <a href={MESH_WEB} target="blank" className="cursor-pointer">
            <img src={MeshWhite} width={30} height={30} alt="mesh" />
          </a>
          <a href={GITBOOK} target="blank" className="cursor-pointer">
            <img src={GitbookLogo} width={22} height={22} alt="gitbook-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
