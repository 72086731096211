export type SwapLeaderboard = {
  "version": "0.1.0",
  "name": "swap_leaderboard",
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "validator",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "markSwap",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "validator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "userState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "volume",
          "type": "u64"
        }
      ]
    },
    {
      "name": "updateUser",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "validator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "userState",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "totalTrades",
          "type": "u16"
        },
        {
          "name": "totalVolume",
          "type": "u64"
        }
      ]
    },
    {
      "name": "updateValidator",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "validator",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "newValidator",
          "type": "publicKey"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "globalState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "validator",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "userState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "walletKey",
            "type": "publicKey"
          },
          {
            "name": "tradesCount",
            "type": "u16"
          },
          {
            "name": "totalVolume",
            "type": "u64"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InvalidValidator",
      "msg": "Invalid validator"
    }
  ]
};

export const IDL: SwapLeaderboard = {
  "version": "0.1.0",
  "name": "swap_leaderboard",
  "instructions": [
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "validator",
          "type": "publicKey"
        }
      ]
    },
    {
      "name": "markSwap",
      "accounts": [
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "validator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "userState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "volume",
          "type": "u64"
        }
      ]
    },
    {
      "name": "updateUser",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "validator",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "userState",
          "isMut": true,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "totalTrades",
          "type": "u16"
        },
        {
          "name": "totalVolume",
          "type": "u64"
        }
      ]
    },
    {
      "name": "updateValidator",
      "accounts": [
        {
          "name": "payer",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "globalState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "validator",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "newValidator",
          "type": "publicKey"
        }
      ]
    }
  ],
  "accounts": [
    {
      "name": "globalState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "validator",
            "type": "publicKey"
          }
        ]
      }
    },
    {
      "name": "userState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "walletKey",
            "type": "publicKey"
          },
          {
            "name": "tradesCount",
            "type": "u16"
          },
          {
            "name": "totalVolume",
            "type": "u64"
          }
        ]
      }
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "InvalidValidator",
      "msg": "Invalid validator"
    }
  ]
};
