import ringMeshIcon from "Assets/Svgs/ring-mesh.svg";
import { truncateDigits } from "Utils/format";
import { Tooltip } from "./tooltip";
import { useSelector } from "Store";

export const TokenPrice = () => {
  const meshPrice = useSelector((state) => state.session.meshPrice);
  return (
    <Tooltip text={"$" + meshPrice.toFixed(4)}>
      <div className="mr-4 sm:mr-4 px-3 py-2 flex items-center bg-base-content rounded">
        <img src={ringMeshIcon} alt="ringMeshIcon" width={25} height={25} />
        <p className="text-primary font-extrabold px-1.5">
          ${truncateDigits(meshPrice)}
        </p>
      </div>
    </Tooltip>
  );
};
