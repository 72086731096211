import { conciseAddress } from "Utils/trunc";
import redirectIcon from "Assets/Svgs/redirect.svg";
import { useSelector } from "Store";
import { checkIfwSOLAddress } from "Utils/judger";

const RedirectAddress = ({ address }: { address: string }) => {
  const explorerURL = useSelector((state) => state.app.currentExplorer);
  const updatedAddress = checkIfwSOLAddress(address);
  return (
    <a
      className="flex accent-on-hover"
      target="_blank"
      href={`${explorerURL}address/${updatedAddress}`}
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="text-xs text-secondary font-medium mr-1">
        {conciseAddress(updatedAddress, 6, 6)}
      </div>
      <img src={redirectIcon} alt="redirectIcon" />
    </a>
  );
};

export default RedirectAddress;
