import axios from "axios";
import _ from "lodash";

import {
  BIRDSEYE_API_BASEURL,
  COINGECKO_BASEURL,
  JUPITER_PRICING_API,
} from "Constants/endpoints";
import { TokensPrices } from "Types/tokens";
import {
  BEPriceResponse,
  CGPriceResponse,
  JUPPriceResponse,
} from "Types/classes";

class PriceManagerClass {
  priceFunctions = [this.jupiter, this.coingecko];
  remainingAddresses: string[] = [];

  prices: TokensPrices = {};

  constructor(addresses: string[]) {
    this.remainingAddresses = addresses;
  }

  async fetchTokensPrices() {
    for (let i = 0; i < this.priceFunctions.length; i++) {
      if (this.remainingAddresses.length === 0) break;
      const res = await this.priceFunctions[i](this.remainingAddresses);
      this.remainingAddresses = res.remaining;
      this.prices = { ...this.prices, ...res.response };
    }
    return this.prices;
  }

  async coingecko(addresses: string[]) {
    const fetchPrice = async (
      address: string
    ): Promise<[string, { usd: number; usd_24h_change: number } | null]> => {
      try {
        const response = await axios.get<CGPriceResponse>(
          `${COINGECKO_BASEURL}/simple/token_price/solana?contract_addresses=${address}%2C&vs_currencies=usd&include_24hr_change=true`,
          {
            headers: {
              "x-cg-demo-api-key": process.env.REACT_APP_COINGECKO_API_KEY,
            },
          }
        );
        if (response.status === 200 && !_.isEmpty(response.data)) {
          return [address, response.data[address]];
        }
        return [address, null];
      } catch (error) {
        return [address, null];
      }
    };

    const promises = addresses.map((address) => fetchPrice(address));
    const results = await Promise.all(promises);

    const priceRecord: TokensPrices = {};
    const remainingTokens: string[] = [];
    results.forEach(([address, priceData]) => {
      if (priceData) {
        priceRecord[address] = {
          price: priceData.usd,
          priceChange: priceData.usd_24h_change,
        };
      } else {
        remainingTokens.push(address);
      }
    });
    return { remaining: remainingTokens, response: priceRecord };
  }

  async birdsEye(addresses: string[]) {
    const multiPriceEndpoint =
      BIRDSEYE_API_BASEURL +
      "multi_price?list_address=" +
      encodeURIComponent(addresses.join(","));

    const { data: response, status } = await axios.get<{
      data: BEPriceResponse;
      success: boolean;
    }>(multiPriceEndpoint, {
      headers: {
        "X-API-KEY": process.env.REACT_APP_BIRDSEYE_API_KEY as string,
      },
    });

    const priceRecord: TokensPrices = {};
    const remainingTokens: string[] = [];
    if (status === 200 && !_.isEmpty(response)) {
      addresses.forEach((address) => {
        if (response.data[address]) {
          const thisToken = response.data[address];
          priceRecord[address] = {
            price: thisToken.value,
            priceChange: thisToken.priceChange24h,
          };
        } else {
          remainingTokens.push(address);
        }
      });
    }
    return { remaining: remainingTokens, response: priceRecord };
  }

  async jupiter(addresses: string[]) {
    const multiPriceEndpoint = JUPITER_PRICING_API + addresses.join(",");

    const { data: response, status } = await axios.get<{
      data: JUPPriceResponse;
    }>(multiPriceEndpoint);

    const priceRecord: TokensPrices = {};
    const remainingTokens: string[] = [];

    if (status === 200 && !_.isEmpty(response)) {
      addresses.forEach((address) => {
        if (response.data[address]) {
          const thisToken = response.data[address];
          priceRecord[address] = {
            price: thisToken.price,
            priceChange: null,
          };
        }
      });
    }
    return { remaining: remainingTokens, response: priceRecord };
  }
}

export default PriceManagerClass;
