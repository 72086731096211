import { useCallback, useEffect, useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { WalletError } from "@solana/wallet-adapter-base";
import * as walletAdapterWallets from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import {
  WalletModalProvider,
  BaseWalletMultiButton,
} from "Components/walletModal";
import { ToastContainer } from "react-toastify";
import mixpanel from "mixpanel-browser";

import Routes from "./Routes";
import { useSelector } from "Store";
import { shouldFetchTokenList } from "Utils/tokens";
import { TokenListsType } from "Types/tokens";
import TokenCacheService from "Classes/tokenCache";

import "react-toastify/dist/ReactToastify.css";
require("@solana/wallet-adapter-react-ui/styles.css");

export const WalletButton = BaseWalletMultiButton;

if (process.env.REACT_APP_ENVIRONMENT === "dev") {
  mixpanel.init("test", { debug: true });
} else {
  mixpanel.init("3c6219328f378ba2b4f72039ea84a168");
}
function App() {
  const lastTokensUpdated = useSelector((state) => state.app.lastTokensUpdated);
  const RPC = useSelector((state) => state.app.currentRPC);

  useEffect(() => {
    (async () => {
      await TokenCacheService.loadTokensList();
      if (await shouldFetchTokenList(lastTokensUpdated)) {
        await TokenCacheService.fetchTokensList(TokenListsType.strict);
        await TokenCacheService.fetchTokensList(TokenListsType.all);
        await TokenCacheService.loadTokensList();
      }
    })();
  }, []);

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/*",
          element: <Routes />,
          errorElement: <></>,
        },
      ]),
    []
  );
  const wallets = useMemo(
    () => [
      new walletAdapterWallets.SolflareWalletAdapter(),
      new walletAdapterWallets.SolongWalletAdapter(),
      new walletAdapterWallets.SafePalWalletAdapter(),
      new walletAdapterWallets.MathWalletAdapter(),
      new walletAdapterWallets.LedgerWalletAdapter(),
      new walletAdapterWallets.HuobiWalletAdapter(),
      new walletAdapterWallets.CoinbaseWalletAdapter(),
      new walletAdapterWallets.CoinhubWalletAdapter(),
      new walletAdapterWallets.Coin98WalletAdapter(),
      new walletAdapterWallets.BitgetWalletAdapter(),
    ],
    []
  );
  const onError = useCallback((error: WalletError) => {
    console.error(error);
  }, []);

  return (
    <ConnectionProvider endpoint={RPC}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect={true}>
        <WalletModalProvider>
          <ToastContainer />
          <RouterProvider router={router} />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
