import React, { ReactNode, useEffect, useRef, useState } from "react";

import settingsIcon from "Assets/Svgs/gear.svg";
import backIcon from "Assets/Svgs/arrow_down.svg";
import { EXPLORERS, NATIVE_SOL, RPC } from "Constants/misc";
import { dispatch, useSelector } from "Store";
import {
  setCurrentExplorer,
  setCurrentRPC,
  setCustomRPC,
  updatePriorityOptions,
} from "Store/Reducers/app";
import { SelectionBox } from "Components/SelectionBox";
import { PriorityLevel, PriorityMode } from "Types/reducers";
import { SwitchTabs } from "Components/switchTabs";
import { fetchPriceFromJupiter } from "Utils/helpers";
import { formatDollarAmount } from "Utils/format";

export const DAppSettings = () => {
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [isPreferredOpen, setIsPreferredOpen] = useState(false);
  const [isPriorityFeeOpen, setIsPriorityFeeOpen] = useState(false);
  const [maxCapDollarValue, setMaxCapDollarValue] = useState<number>(0);
  const priorityLevel = useSelector((state) => state.app.priorityLevel);
  const priorityMode = useSelector((state) => state.app.priorityMode);
  const exactFee = useSelector((state) => state.app.exactFee);
  const maxCap = useSelector((state) => state.app.maxCap);
  const [userInputFee, setUserInputFee] = useState(maxCap);
  const [level, setLevel] = useState<PriorityLevel>(priorityLevel);
  const [mode, setMode] = useState<PriorityMode>(priorityMode);

  const handleSelectionBox = (text: PriorityLevel) => {
    setLevel(text);
  };

  const handlePriorityModeChange = (text: PriorityMode) => {
    if (text === PriorityMode.EXACT_FEE) {
      setUserInputFee(exactFee);
    } else if (text === PriorityMode.MAX_CAP) {
      setUserInputFee(maxCap);
    }
    setMode(text);
  };

  const handleSavePrioritySettings = () => {
    dispatch(updatePriorityOptions({ mode, level, customInput: userInputFee }));
    setIsPriorityFeeOpen(false);
  };

  const togglePreferredRPCModal = () => {
    setIsPreferredOpen(!isPreferredOpen);
  };

  const togglePriorityFeeModal = () => {
    resetPriorityFeesOptions();
    setIsPriorityFeeOpen(!isPriorityFeeOpen);
  };

  const resetPriorityFeesOptions = () => {
    setMode(priorityMode);
    setLevel(priorityLevel);
    setUserInputFee(
      priorityMode === PriorityMode.EXACT_FEE ? exactFee : maxCap
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (Number(inputValue) > 1000) {
      return;
    }
    setUserInputFee(inputValue !== "" ? Number(inputValue) : undefined);
  };

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsSettingOpen(false);
        setIsPreferredOpen(false);
        setIsPriorityFeeOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const price = await fetchPriceFromJupiter(NATIVE_SOL);
      if (price) {
        setMaxCapDollarValue(price * (userInputFee ?? 0));
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [userInputFee]);

  return (
    <div ref={menuRef}>
      <div
        className="bg-base-content rounded-full w-9 h-9 p-2 mr-4 cursor-pointer"
        onClick={() => setIsSettingOpen(!isSettingOpen)}
        id="menu-button"
        aria-expanded="true"
        aria-haspopup="true"
      >
        <img
          src={settingsIcon}
          width={25}
          height={25}
          className="accent-on-hover icon"
          alt="settingsIcon"
        />
      </div>
      {isSettingOpen && !isPreferredOpen && !isPriorityFeeOpen && (
        <MenuCard>
          <div className="text-xl font-medium my-2">Settings</div>
          <div className="text-sm font-medium text-secondary">
            You can change settings from this panel.
          </div>
          <div
            className="bg-base-100 flex justify-between items-center px-3 rounded my-2 mr-1 py-4 cursor-pointer"
            onClick={togglePreferredRPCModal}
          >
            <p className="text-sm font-medium">Preferred Explorer</p>
            <img
              src={backIcon}
              className="cursor-pointer accent-on-hover"
              alt="backIcon"
              style={{ transform: "rotate(-90deg)", width: "14px" }}
              onClick={togglePreferredRPCModal}
            />
          </div>
          <div
            className="bg-base-100 flex justify-between items-center px-3 rounded my-2 mr-1 py-4 cursor-pointer"
            onClick={togglePriorityFeeModal}
          >
            <p className="text-sm font-medium">Transaction Priority</p>
            <img
              src={backIcon}
              className="cursor-pointer accent-on-hover"
              alt="backIcon"
              style={{ transform: "rotate(-90deg)", width: "14px" }}
              onClick={togglePriorityFeeModal}
            />
          </div>
          <div className="mt-4">
            <p className="text-sm font-medium text-secondary my-2">
              RPC Endpoints
            </p>
            <div className="flex flex-wrap items-center gap-3">
              {RPC.map((item, index) => (
                <RPCTile
                  key={index}
                  rpcLink={item.link}
                  rpcName={item.name}
                  time={item.time}
                  index={index}
                />
              ))}
            </div>
          </div>
          <div className="my-2 mt-4">
            <p className="text-sm font-medium text-secondary mb-2">
              Custom RPC
            </p>
            <CustomRPC />
          </div>
        </MenuCard>
      )}
      {isPreferredOpen && (
        <MenuCard>
          <div className="flex items-center gap-x-4">
            <img
              src={backIcon}
              className="cursor-pointer accent-on-hover"
              alt="backIcon"
              style={{ transform: "rotate(90deg)", width: "20px" }}
              onClick={togglePreferredRPCModal}
            />
            <p className="text-xl font-medium">Preferred Explorer</p>
          </div>
          <div className="mt-4 flex flex-col gap-3">
            {EXPLORERS.map((item) => (
              <ExplorerTile key={item.name} explorer={item} />
            ))}
          </div>
        </MenuCard>
      )}
      {isPriorityFeeOpen && (
        <MenuCard>
          <div className="flex items-center gap-x-4">
            <img
              src={backIcon}
              className="cursor-pointer accent-on-hover"
              alt="backIcon"
              style={{ transform: "rotate(90deg)", width: "20px" }}
              onClick={togglePriorityFeeModal}
            />
            <p className="text-xl font-medium">Global Priority Fee</p>
          </div>
          <div className="text-secondary font-medium text-sm my-3">
            These fees apply to all features of the Mesh Protocol, including the
            current swap aggregator and future additions.
          </div>
          {mode === PriorityMode.MAX_CAP ? (
            <div
              className={`transition ${
                mode === PriorityMode.MAX_CAP ? "expanded" : ""
              }`}
            >
              <p className="text-secondary font-medium text-sm">
                Priority Level
              </p>
              <div className="flex items-center gap-4 mt-2">
                <SelectionBox
                  text={PriorityLevel.AUTO}
                  selected={level === PriorityLevel.AUTO}
                  setSelected={handleSelectionBox}
                />
                <SelectionBox
                  text={PriorityLevel.FAST}
                  selected={level === PriorityLevel.FAST}
                  setSelected={handleSelectionBox}
                />
                <SelectionBox
                  text={PriorityLevel.TURBO}
                  selected={level === PriorityLevel.TURBO}
                  setSelected={handleSelectionBox}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="flex items-center justify-between my-4">
            <p className="text-secondary font-medium text-sm">Priority Mode</p>
            <SwitchTabs
              tabs={[
                { text: PriorityMode.MAX_CAP },
                { text: PriorityMode.EXACT_FEE },
              ]}
              currentTab={mode}
              onChange={handlePriorityModeChange}
            />
          </div>
          <div className="text-secondary font-medium text-sm">
            Mesh intelligently minimizes and decides the best fee for you. Set a
            max cap to prevent overpaying.
          </div>
          <div className="mt-3">
            <div className="flex justify-between items-center mb-3">
              <p className="text-secondary text-sm">
                {mode === PriorityMode.MAX_CAP
                  ? "Set Max Cap"
                  : "Set Exact Fee"}
              </p>
              <p
                className="text-secondary text-sm"
                title={maxCapDollarValue.toString()}
              >
                ~{formatDollarAmount(maxCapDollarValue)}
              </p>
            </div>
            <div className="input-container">
              <input
                type="number"
                value={userInputFee ?? ""}
                className="input-with-gradient-border"
                placeholder={
                  mode === PriorityMode.MAX_CAP
                    ? "Enter Max Cap"
                    : "Enter Exact Fee"
                }
                onChange={handleInputChange}
                maxLength={5}
                max={1000}
              />
              <span className="sol-text">SOL</span>
            </div>
          </div>
          <div className="rounded w-full text-center font-semibold bg-black mt-3">
            <button
              className="rounded mesh-text-gradient w-fit bg-black p-2"
              onClick={handleSavePrioritySettings}
            >
              Save Changes
            </button>
          </div>
        </MenuCard>
      )}
    </div>
  );
};

export const RPCTile = ({
  index,
  rpcName,
  time,
  rpcLink,
}: {
  index: number;
  rpcName: string;
  time: number;
  rpcLink: string;
}) => {
  const colors = ["#4DBE9C", "#F5FA80", "#BA5751", "#BA5751"];
  const isCustomRPC = useSelector((state) => state.app.isCustomRPC);
  const currentRPC = useSelector((state) => state.app.currentRPC);
  const setRPC = () => {
    dispatch(setCustomRPC(false));
    dispatch(setCurrentRPC(rpcLink));
  };
  return (
    <div
      className={`${
        !isCustomRPC && currentRPC === rpcLink ? "gradient-border !rounded" : ""
      }`}
    >
      <div className={`border border-white/[0.1] rounded cursor-pointer`}>
        <div
          onClick={setRPC}
          className="p-2 py-3 min-w-36 md:min-w-44 bg-base-100 rounded flex flex-col items-center"
        >
          <p className="text-sm font-medium">{rpcName}</p>
          <li className={`text-2xl`} style={{ color: colors[index] }}>
            <span className="text-secondary text-xs ml-[-1rem]">{time}ms</span>
          </li>
        </div>
      </div>
    </div>
  );
};

export const CustomRPC = () => {
  const [error, setError] = useState("");
  const isCustomRPC = useSelector((state) => state.app.isCustomRPC);
  const currentRPC = useSelector((state) => state.app.currentRPC);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (isCustomRPC) {
      setInputValue(currentRPC);
    } else {
      setInputValue("");
    }
  }, [isCustomRPC]);

  const handleSave = () => {
    if (inputValue.startsWith("https://")) {
      dispatch(setCustomRPC(true));
      dispatch(setCurrentRPC(inputValue));
      setError("");
    } else {
      setError("Invalid URL");
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.startsWith("https://")) {
      setError("");
    } else {
      setError("Invalid URL");
    }
    setInputValue(e.target.value);
  };

  return (
    <>
      <div className={`${isCustomRPC ? "gradient-border" : ""} !rounded mt-2`}>
        <div className="flex items-center">
          <input
            type="text"
            className="rounded-l w-60 md:w-full px-4 py-3 bg-base-100 outline-none placeholder:text-secondary placeholder:text-xs"
            value={inputValue}
            onChange={handleChange}
            placeholder="Enter Custom RPC URL Here"
          />
          <button
            className="bg-base-100 text-primary text-xs font-semibold rounded-r px-4 py-3 focus:outline-none"
            onClick={handleSave}
            disabled={error || inputValue === "" ? true : false}
          >
            <div
              className={`px-3 py-1 rounded ${
                error || inputValue === ""
                  ? "bg-base-content cursor-not-allowed"
                  : "mesh-gradient"
              }`}
            >
              {isCustomRPC && currentRPC && inputValue !== ""
                ? "Saved"
                : "Save"}
            </div>
          </button>
        </div>
      </div>
      {error && inputValue !== "" && (
        <p className="text-red-500 text-xs mt-1">{error}</p>
      )}
    </>
  );
};

export type ExplorerType = {
  name: string;
  link: string;
};

export const ExplorerTile = ({ explorer }: { explorer: ExplorerType }) => {
  const currentExplorer = useSelector((state) => state.app.currentExplorer);

  const handleClick = () => {
    dispatch(setCurrentExplorer(explorer.link));
  };
  return (
    <div
      className="flex items-center ps-4 rounded bg-base-100 cursor-pointer"
      onClick={handleClick}
    >
      <input
        checked={explorer.link === currentExplorer}
        id={`bordered-radio-${explorer.name}`}
        type="radio"
        value=""
        name="bordered-radio"
        className="gradient-radio w-5 h-5"
      />
      <label
        htmlFor={`bordered-radio-${explorer.name}`}
        className={`w-full py-4 ms-2 text-sm font-medium cursor-pointer ${
          explorer.link === currentExplorer ? "text-primary" : "text-secondary"
        }`}
      >
        {explorer.name}
      </label>
    </div>
  );
};

export const MenuCard = ({ children }: { children: ReactNode }) => {
  return (
    <div className="absolute right-2 rounded top-16 z-20 mt-2 w-[360px] min-w-[360px] md:w-[415px] md:min-w-[415px]">
      <div className="gradient-border !p-[2px] !m-[2px] !rounded">
        <div
          className=" p-4 origin-top-right rounded bg-base-content shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
