import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { Jupiter } from "Classes/jupiter";
import * as anchor from "@coral-xyz/anchor";
import { Program } from "@coral-xyz/anchor";
import axios from "axios";

import { IDL, SwapLeaderboard } from "Idl/swap_leaderboard";
import { QUICKNODE_MAINNET_RPC } from "Constants/endpoints";
import { store } from "Store";
import { setBalanceLoading, setQouteLoading } from "Store/Reducers/loadings";
import {
  setQoute,
  setUserTokenBalances,
  setUserTokens,
} from "Store/Reducers/session";
import { ParsedTokenInfo, UserTokenBalances, UserTokens } from "Types/tokens";
import { LeaderboardType, SolanaFeeInfoJson } from "Types/misc";
import { PriorityLevel, PriorityMode } from "Types/reducers";
import { SOL_to_microLamports, formatUnits } from "./format";
import { NATIVE_SOL_TOKENINFO, WSOL_TOKENINFO } from "Constants/tokens";
import PriceManagerClass from "Classes/priceManager";

export const fetchUserTokens = async (
  connection: Connection,
  walletPubKey: PublicKey | null
) => {
  let userTokens: UserTokens = {};
  try {
    store.dispatch(setBalanceLoading(true));
    const lamportBalance = await connection.getBalance(
      walletPubKey as PublicKey
    );
    if (Boolean(lamportBalance)) {
      const solAmount = lamportBalance / LAMPORTS_PER_SOL;
      userTokens[NATIVE_SOL_TOKENINFO.address] = {
        amount: lamportBalance.toString(),
        decimals: 9,
        uiAmount: solAmount,
        uiAmountString: solAmount.toString(),
      };
    }
    const { value: tokenHoldings } =
      await connection.getParsedTokenAccountsByOwner(
        walletPubKey as PublicKey,
        {
          programId: TOKEN_PROGRAM_ID,
        }
      );
    if (tokenHoldings.length > 0) {
      tokenHoldings.forEach((token) => {
        const parseInfo = token.account.data.parsed.info as
          | ParsedTokenInfo
          | undefined;
        if (parseInfo) {
          // check if user holds some wSOL then add it into the balances as wSOL
          userTokens[
            parseInfo.isNative ? WSOL_TOKENINFO.address : parseInfo.mint
          ] = parseInfo.tokenAmount;
        }
      });
    }
    store.dispatch(setUserTokens(userTokens));
    store.dispatch(setBalanceLoading(false));
  } catch (error) {
    console.log("Unable to fetch user Tokens");
    store.dispatch(setBalanceLoading(false));
  }
  return userTokens;
};

export const fetchPricesOfUserTokens = async (userTokens: UserTokens) => {
  const userTokenAddresses = Object.keys(userTokens);
  const balances: UserTokenBalances = {};
  let tempUserTokens = { ...userTokens };
  delete tempUserTokens[WSOL_TOKENINFO.address]; // delete this address since it is arbitrary and does not exist in real
  const prices = await new PriceManagerClass(
    Object.keys(tempUserTokens)
  ).fetchTokensPrices();
  userTokenAddresses.forEach((tokenAddress) => {
    const token = userTokens[tokenAddress];
    const tokenBalance = formatUnits(Number(token.amount), token.decimals);
    const tokenPrice =
      tokenAddress === WSOL_TOKENINFO.address // check if user holds wsol then add the price of sol
        ? prices?.[NATIVE_SOL_TOKENINFO.address]?.price ?? 0
        : prices?.[tokenAddress]?.price ?? 0;

    const tokenAmount = tokenPrice * tokenBalance;
    balances[tokenAddress] = {
      balance: tokenBalance,
      amount: tokenAmount,
    };
  });
  store.dispatch(setUserTokenBalances(balances));
};

export const fetchRoutePlan = async (jupiter: Jupiter) => {
  try {
    const jupiterRouterPlan = await jupiter.getRoutePlan();
    console.log("jupiterRouterPlan => ", jupiterRouterPlan);
    store.dispatch(setQoute(jupiterRouterPlan));
    store.dispatch(setQouteLoading(false));
  } catch (error) {
    console.log("getQoute ERROR", error);
    store.dispatch(setQoute(undefined));
    store.dispatch(setQouteLoading(false));
  }
};

export const leaderboard = async () => {
  const connection = new anchor.web3.Connection(QUICKNODE_MAINNET_RPC);

  const program = new Program(
    IDL,
    new anchor.web3.PublicKey("5YkQVo6GPpKg6hzt6BtdvKGKxQEyoBTK8pGGvMoYxedo"),
    { connection }
  ) as Program<SwapLeaderboard>;

  const users = await program.account.userState.all();

  const data: LeaderboardType[] = [];
  users.forEach((e) => {
    data.push({
      walletKey: e.account.walletKey.toBase58(),
      tradesCount: e.account.tradesCount,
      totalVolume: e.account.totalVolume.toNumber() / Math.pow(10, 9),
    });
  });
  return rankUsers(data);
};

export const fetchUserScore = async (userKey: PublicKey | null) => {
  if (!userKey) {
    return 0;
  }
  try {
    const connection = new anchor.web3.Connection(QUICKNODE_MAINNET_RPC);

    const program = new Program(
      IDL,
      new anchor.web3.PublicKey("5YkQVo6GPpKg6hzt6BtdvKGKxQEyoBTK8pGGvMoYxedo"),
      { connection }
    ) as Program<SwapLeaderboard>;

    const [userPDA] = anchor.web3.PublicKey.findProgramAddressSync(
      [userKey.toBuffer(), Buffer.from("user_state")],
      program.programId
    );
    const user = await program.account.userState.fetch(userPDA);
    return user.totalVolume.toNumber() / 1e9;
  } catch (error) {
    console.log("Error fetching user leaderboard score", error);
    return 0;
  }
};

function rankUsers(
  users: {
    rank?: number;
    walletKey: string;
    totalVolume: number;
    tradesCount: number;
  }[]
) {
  users.sort(
    (a, b) => b.totalVolume - a.totalVolume || b.tradesCount - a.tradesCount
  );
  for (let i = 0; i < users.length; i++) {
    users[i].rank = i + 1;
  }
  const rankedUsers = users.map((user) => ({ rank: user.rank, ...user }));

  return rankedUsers;
}

export async function getComputeBudget(computeUnits: number = 300000) {
  const {
    priorityLevel,
    priorityMode,
    PriorityFeeLevelsValues,
    exactFee,
    maxCap,
  } = store.getState().app;

  let transactionPriority =
    priorityMode === PriorityMode.EXACT_FEE
      ? exactFee
      : PriorityFeeLevelsValues[priorityLevel];

  if (!transactionPriority) {
    transactionPriority = PriorityFeeLevelsValues.Fast;
  }

  const maxMicroLamportPerUnit =
    SOL_to_microLamports(maxCap ?? 0.0003) / computeUnits;

  if (priorityLevel !== PriorityLevel.AUTO) {
    console.log(
      "transactionPriority",
      transactionPriority,
      SOL_to_microLamports(transactionPriority),
      SOL_to_microLamports(transactionPriority) / computeUnits
    );
    return {
      units: computeUnits,
      microLamports: Math.min(
        Math.ceil(SOL_to_microLamports(transactionPriority) / computeUnits),
        maxMicroLamportPerUnit
      ),
    };
  } else {
    return {
      units: computeUnits,
      microLamports: Math.min(
        Math.ceil(SOL_to_microLamports(transactionPriority) / computeUnits),
        maxMicroLamportPerUnit
      ),
    };
  }
}

export const fetchLatestPriorityFee = async () => {
  const response = await axios.get<SolanaFeeInfoJson>(
    "https://solanacompass.com/api/fees"
  );

  if (response.status === 200) {
    return response.data?.["15"]?.avg ?? undefined;
  }
  return undefined;
};
