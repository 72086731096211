import { PriorityLevel } from "Types/reducers";

type SelectionBoxProps = {
  text: PriorityLevel;
  selected: boolean;
  setSelected: (val: PriorityLevel) => void;
};
export const SelectionBox = ({
  text,
  selected,
  setSelected,
}: SelectionBoxProps) => {
  return (
    <div
      className={`${
        selected ? "gradient-border !m-[2px] !p-[2px] !rounded" : ""
      }`}
    >
      <div
        className="bg-base-100 w-24 md:w-28 text-center p-1.5 px-5 cursor-pointer rounded"
        onClick={() => setSelected(text)}
      >
        <p className={`font-semibold ${selected ? "mesh-text-gradient " : ""}`}>
          {text}
        </p>
      </div>
    </div>
  );
};
