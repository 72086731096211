import { WalletContextState } from "@solana/wallet-adapter-react";
import promiseRetry from "promise-retry";
import { toast } from "react-toastify";
import {
  BlockhashWithExpiryBlockHeight,
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionExpiredBlockheightExceededError,
  VersionedTransactionResponse,
} from "@solana/web3.js";
import {
  NATIVE_MINT,
  TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  createSyncNativeInstruction,
  getAccount,
  getAssociatedTokenAddressSync,
  createCloseAccountInstruction,
} from "@solana/spl-token";

import { wait } from "./wait";
import { NATIVE_SOL_TOKENINFO } from "Constants/tokens";
import CachedService from "Classes/cachedService";
import {
  TxCanceledToast,
  TxFailedToast,
  TxProgressToast,
  TxSignToast,
  WrapUnwrapSuccessToast,
} from "Components/toasts";
import { store } from "Store";
import {
  setIsApprovalPending,
  setIsTxInProgress,
} from "Store/Reducers/loadings";
import {
  incrementSuccessTxCount,
  resetAmounts,
  setIsFirstQoute,
  setPercentage,
  setQoute,
} from "Store/Reducers/session";
import { fetchRoutePlan } from "./fetchers";
import { Jupiter } from "Classes/jupiter";
import { Percentage } from "Types/reducers";

type TransactionSenderAndConfirmationWaiterArgs = {
  connection: Connection;
  serializedTransaction: Buffer | Uint8Array | number[];
  blockhashWithExpiryBlockHeight: BlockhashWithExpiryBlockHeight;
};

const SEND_OPTIONS = {
  skipPreflight: true,
  maxRetries: 0,
};

export async function transactionSenderAndConfirmationWaiter({
  connection,
  serializedTransaction,
  blockhashWithExpiryBlockHeight,
}: TransactionSenderAndConfirmationWaiterArgs): Promise<VersionedTransactionResponse | null> {
  const txid = await connection.sendRawTransaction(
    serializedTransaction,
    SEND_OPTIONS
  );

  const controller = new AbortController();
  const abortSignal = controller.signal;

  const abortableResender = async () => {
    while (true) {
      await wait(2_000);
      if (abortSignal.aborted) return;
      try {
        console.log("sending raw transaction again");
        await connection.sendRawTransaction(
          serializedTransaction,
          SEND_OPTIONS
        );
      } catch (e) {
        console.warn(`Failed to resend transaction: ${e}`);
      }
    }
  };

  try {
    abortableResender();
    const lastValidBlockHeight =
      blockhashWithExpiryBlockHeight.lastValidBlockHeight - 150;

    // this would throw TransactionExpiredBlockheightExceededError
    await Promise.race([
      connection.confirmTransaction(
        {
          ...blockhashWithExpiryBlockHeight,
          lastValidBlockHeight,
          signature: txid,
          abortSignal,
        },
        "finalized"
      ),
      new Promise(async (resolve) => {
        // in case ws socket died
        while (!abortSignal.aborted) {
          await wait(2_000);
          const tx = await connection.getSignatureStatus(txid, {
            searchTransactionHistory: false,
          });
          if (tx?.value?.confirmationStatus === "finalized") {
            resolve(tx);
          }
        }
      }),
    ]);
  } catch (e) {
    if (e instanceof TransactionExpiredBlockheightExceededError) {
      // we consume this error and getTransaction would return null
      return null;
    } else {
      // invalid state from web3.js
      throw e;
    }
  } finally {
    controller.abort();
  }

  // in case rpc is not synced yet, we add some retries
  const response = promiseRetry(
    async (retry) => {
      console.log("checking tx response after confirmation");
      const response = await connection.getTransaction(txid, {
        commitment: "finalized",
        maxSupportedTransactionVersion: 0,
      });
      if (!response) {
        console.log("retry for tx response after confirmation");
        retry(response);
      }
      return response;
    },
    {
      retries: 5,
      minTimeout: 1e3,
    }
  );

  return response;
}

export const wrapSol = async (
  connection: Connection,
  wallet: WalletContextState,
  amount: string
) => {
  let userPublickey = wallet.publicKey;
  if (userPublickey) {
    store.dispatch(setIsTxInProgress(true));
    const wsolATA = getAssociatedTokenAddressSync(
      new PublicKey(NATIVE_SOL_TOKENINFO.address),
      userPublickey,
      false,
      TOKEN_PROGRAM_ID
    );
    let isWsolATAexists = false;
    try {
      const ata = await getAccount(
        connection,
        wsolATA,
        "confirmed",
        TOKEN_PROGRAM_ID
      );
      if (ata) {
        isWsolATAexists = true;
      }
    } catch (error) {
      isWsolATAexists = false;
    }
    const wrapSolTx = new Transaction();
    if (!isWsolATAexists) {
      wrapSolTx.add(
        createAssociatedTokenAccountInstruction(
          userPublickey,
          wsolATA,
          userPublickey,
          NATIVE_MINT
        )
      );
    }
    wrapSolTx.add(
      SystemProgram.transfer({
        fromPubkey: userPublickey,
        toPubkey: wsolATA,
        lamports: +amount * LAMPORTS_PER_SOL,
      }),
      createSyncNativeInstruction(wsolATA)
    );
    if (wallet.signTransaction) {
      CachedService.TxProgressToast(<TxSignToast />);
      store.dispatch(setIsApprovalPending(true));

      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();
      wrapSolTx.recentBlockhash = blockhash;
      wrapSolTx.feePayer = userPublickey;

      await wallet
        .signTransaction(wrapSolTx)
        .then(async (signedTx) => {
          toast.dismiss();
          CachedService.TxProgressToast(<TxProgressToast />);
          store.dispatch(setIsApprovalPending(false));

          const transactionResponse =
            await transactionSenderAndConfirmationWaiter({
              connection,
              serializedTransaction: signedTx.serialize(),
              blockhashWithExpiryBlockHeight: {
                blockhash,
                lastValidBlockHeight,
              },
            });

          // If we are not getting a response back, the transaction has not confirmed.
          if (!transactionResponse) {
            console.error("Transaction not confirmed");
            TxFailChore(<TxFailedToast />, true);
            return;
          }

          if (transactionResponse.meta?.err) {
            TxFailChore(<TxFailedToast />, true);
            console.error("transactionResponse in error", transactionResponse);
            return;
          }

          if (transactionResponse) {
            const txId = transactionResponse.transaction.signatures[0];
            console.log("success transactionResponse", transactionResponse);
            toast.dismiss();
            CachedService.successToast(
              <WrapUnwrapSuccessToast txId={txId} amountA={amount} isWrap />
            );
            store.dispatch(setQoute(undefined));
            store.dispatch(incrementSuccessTxCount());
            store.dispatch(resetAmounts());
            store.dispatch(setPercentage(Percentage._0));
          }
        })
        .catch((err) => {
          console.log("sign transaction failed", err);
          store.dispatch(setIsApprovalPending(false));
          TxFailChore(<TxCanceledToast />, true);
        });
    }
    store.dispatch(setIsTxInProgress(false));
  }
};

export const unwrapSol = async (
  connection: Connection,
  wallet: WalletContextState,
  amount: string
) => {
  let userPublickey = wallet.publicKey;
  if (userPublickey) {
    store.dispatch(setIsTxInProgress(true));
    const wsolATA = getAssociatedTokenAddressSync(
      new PublicKey(NATIVE_SOL_TOKENINFO.address),
      userPublickey,
      false,
      TOKEN_PROGRAM_ID
    );
    const unwrapSolTx = new Transaction();
    unwrapSolTx.add(
      createCloseAccountInstruction(wsolATA, userPublickey, userPublickey)
    );

    if (wallet.signTransaction) {
      CachedService.TxProgressToast(<TxSignToast />);
      store.dispatch(setIsApprovalPending(true));

      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();
      unwrapSolTx.recentBlockhash = blockhash;
      unwrapSolTx.feePayer = userPublickey;

      await wallet
        .signTransaction(unwrapSolTx)
        .then(async (signedTx) => {
          toast.dismiss();
          CachedService.TxProgressToast(<TxProgressToast />);
          store.dispatch(setIsApprovalPending(false));

          const transactionResponse =
            await transactionSenderAndConfirmationWaiter({
              connection,
              serializedTransaction: signedTx.serialize(),
              blockhashWithExpiryBlockHeight: {
                blockhash,
                lastValidBlockHeight,
              },
            });

          // If we are not getting a response back, the transaction has not confirmed.
          if (!transactionResponse) {
            console.error("Transaction not confirmed");
            TxFailChore(<TxFailedToast />, true);
            return;
          }

          if (transactionResponse.meta?.err) {
            TxFailChore(<TxFailedToast />, true);
            console.error("transactionResponse in error", transactionResponse);
            return;
          }

          if (transactionResponse) {
            const txId = transactionResponse.transaction.signatures[0];
            console.log("success transactionResponse", transactionResponse);
            toast.dismiss();
            CachedService.successToast(
              <WrapUnwrapSuccessToast
                txId={txId}
                amountA={amount}
                isWrap={false}
              />
            );
            store.dispatch(setQoute(undefined));
            store.dispatch(incrementSuccessTxCount());
            store.dispatch(resetAmounts());
            store.dispatch(setPercentage(Percentage._0));
          }
        })
        .catch((err) => {
          console.log("sign transaction failed", err);
          store.dispatch(setIsApprovalPending(false));
          TxFailChore(<TxCanceledToast />, true);
        });
    }
    store.dispatch(setIsTxInProgress(false));
  }
};

export const TxFailChore = (
  FailToast: JSX.Element,
  isWrapUnwrap: boolean = false
) => {
  toast.dismiss();
  CachedService.errorToast(FailToast);
  store.dispatch(setIsFirstQoute(false));
  !isWrapUnwrap && fetchRoutePlan(new Jupiter());
};
