import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";

import CopyIcon from "Assets/Svgs/copy.svg";
import CheckIcon from "Assets/Svgs/checkIcon.svg";

export const CopyText = ({ text }: { text: string }) => {
  const notify = () =>
    toast.success("Copied!", {
      position: "bottom-right",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toastify-container",
      progressClassName: "toastify-progress",
      icon: ({ theme, type }) => <img src={CheckIcon} alt="checkIcon" />,
    });

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        notify();
      }}
    >
      <img src={CopyIcon} alt="copy-icon" />
    </CopyToClipboard>
  );
};
