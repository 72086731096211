export enum TwBreakPoints {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

export enum Environment {
  local = "local",
  dev = "dev",
  prod = "prod",
}

export enum logsErrors {
  _6001 = "6001",
  _6030 = "6030",
  _6035 = "6035",
}

export type LeaderboardType = {
  rank?: number;
  walletKey: string;
  totalVolume: number;
  tradesCount: number;
};

export type ChartData = Array<[number, number]>;

export enum ChartColors {
  profit = "text-green-600",
  loss = "text-red-600",
  neutral = "text-white",
}

interface SolanaFeeInfo {
  min: number;
  max: number;
  avg: number;
  priorityTx: number;
  nonVotes: number;
  priorityRatio: number;
  avgCuPerBlock: number;
  blockspaceUsageRatio: number;
}
export type SolanaFeeInfoJson = {
  "1": SolanaFeeInfo;
  "5": SolanaFeeInfo;
  "15": SolanaFeeInfo;
};
