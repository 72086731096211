import { Environment } from "Types/misc";
import { clusterApiUrl } from "@solana/web3.js";
import {
  ALCHEMY_RPC,
  ANKR_RPC,
  HELIUS_RPC,
  QUICKNODE_MAINNET_RPC,
} from "./endpoints";

export const TOKENLIST_EXPIRE_PERIOD = 24 * 60 * 60 * 1000;
export const NATIVE_SOL = "So11111111111111111111111111111111111111112";
export const minimumSOLbalance = 0.00293516;
export const TELEGRAM_LINK = "https://t.me/meshprotocol";
export const X_HANDLE = "https://twitter.com/mesh_protocol";
export const EMAIL = "contact@meshprotocol.xyz";
export const GITBOOK = "https://docs.meshprotocol.xyz/";
export const MESH_WEB = "https://meshprotocol.xyz";
export const MESH_MEDIUM_URL = "https://meshprotocol.medium.com/";
export const AMOUNT_INPUT_REGEX = /^((\d+[.]?\d*)|(\.\d+))$/;
export const RADIYUM_URL =
  "https://raydium.io/swap/?inputCurrency=sol&outputCurrency=MESHwqmXvAmKpDYSgRZkm9D5H8xYSCVixeyZoePHn4G&fixed=in";
export const MEXC_URL = "https://www.mexc.com/";
export const ORCA_URL = "https://www.orca.so/";

export const MESH_STAKING_URL = "https://staking.meshprotocol.xyz";
export const MESH_SWAP_URL = "https://swap.meshprotocol.xyz";

export const MESH_ANALYTICS_URL =
  "https://dune.com/luffythedev/mesh-swap-leaderboard";
export const MESH_LIQUIDITY_URL = "https://app.meshprotocol.xyz/";

export const WALLET_BTN_LABELS = {
  "change-wallet": "Change Wallet",
  connecting: "Connecting ...",
  "copy-address": "Copy Address",
  copied: "Copied",
  disconnect: "Disconnect",
  "has-wallet": "Connect",
  "no-wallet": "Connect Wallet",
} as const;

export const networkRPC =
  process.env.REACT_APP_ENVIRONMENT === Environment.local
    ? "http://localhost:8899"
    : process.env.REACT_APP_ENVIRONMENT === Environment.dev
    ? clusterApiUrl("devnet")
    : clusterApiUrl("mainnet-beta");

export const solanaExplorerUrl = "https://explorer.solana.com/";
export const solscanURL = "https://solscan.io/";
export const solanaFmURL = "https://solana.fm/";

export const EXPLORERS = [
  { name: "Solana Explorer", link: solanaExplorerUrl },
  { name: "Solscan", link: solscanURL },
  { name: "Solana FM", link: solanaFmURL },
];

export const RPC = [
  { name: "Quicknode RPC", time: 180, link: QUICKNODE_MAINNET_RPC },
  { name: "Alchemy RPC", time: 180, link: ALCHEMY_RPC },
  { name: "Helius RPC", time: 180, link: HELIUS_RPC },
  { name: "Ankr RPC", time: 180, link: ANKR_RPC },
];

export const navbarLinks = [
  { name: "Liquidity", url: MESH_LIQUIDITY_URL },
  { name: "Swap", url: MESH_SWAP_URL },
  { name: "Stake", url: MESH_STAKING_URL },
];
