import numbro from "numbro";
import { parseUnits as parseUnitsEthers } from "ethers";
import * as anchor from "@coral-xyz/anchor";

import { ChartData, Environment } from "Types/misc";
import { validateDecimalPlaces } from "./judger";
import { SlippageValues } from "Types/reducers";
import { store } from "Store";

export const formatDollarAmount = (
  num: number | undefined | null,
  digits = 2,
  round = true
) => {
  if (num === 0) return "$0.00";
  if (!num) return "-";
  if (num < 0.001 && digits <= 3) {
    return "<$0.001";
  }
  if (num < 0.01 && digits <= 2) {
    return "<$0.01";
  }

  return numbro(num).formatCurrency({
    average: round,
    mantissa: num > 1000 ? 2 : digits,
    abbreviations: {
      million: "M",
      billion: "B",
    },
  });
};

export const formatTokenAmount = (num: number | undefined, digits = 2) => {
  if (num === 0) return "0";
  if (!num) return "-";
  if (num < 0.001 && digits <= 3) {
    return "<0.001";
  }
  if (num < 0.01 && digits <= 2) {
    return "<0.01";
  }

  let formattedAmount = numbro(num)
    .formatCurrency({
      average: true,
      mantissa: num >= 1000 ? 2 : digits,
      abbreviations: {
        million: "M",
        billion: "B",
      },
    })
    .replace("$", "");

  formattedAmount = formattedAmount.replace(".00", "");
  return formattedAmount;
};

export function parseUnits(_num: string, decimals: number) {
  return new anchor.BN(parseUnitsEthers(_num, decimals).toString());
}

export function formatUnits(_num: anchor.BN | number, decimals: number) {
  const divisor = 10 ** decimals;
  return typeof _num === "number"
    ? _num / divisor
    : parseFloat(_num.toString()) / divisor;
}

export const generateExplorerTxLink = (txId?: string) => {
  const currentExplorer = store.getState().app.currentExplorer;
  return `${currentExplorer}/tx/${txId}?cluster=${
    process.env.REACT_APP_ENVIRONMENT === Environment.dev ? "devnet" : "mainnet"
  }`;
};

export const truncateDigits = (num: number) => {
  if (typeof num !== "number") {
    return undefined;
  }
  return Math.floor(num * 100) / 100;
};

export const exactAmountInDecimals = (amount: number, decimals: number) => {
  return Number.isInteger(amount)
    ? amount.toString()
    : amount.toFixed(decimals).replace(/0+$/, "");
};

export const pickEvenlySpacedValues = (array: Array<any>) => {
  if (array.length <= 20) return array;

  const stepSize = Math.floor(array.length / 20);
  const result = [];

  for (let i = 0; i < 20; i++) {
    result.push(array[i * stepSize]);
  }

  return result;
};

export const formatDexesNames = (names: string[]) => {
  if (names.length > 2) {
    return `${names.slice(0, 2).join(", ")}...`;
  } else {
    return names.join(", ");
  }
};

export const stripDecimalsIfMore = (amount: string, decimals: number) => {
  if (!validateDecimalPlaces(amount, decimals)) {
    amount = Number(amount).toFixed(decimals);
  }
  return amount;
};

export const formatSlippageForJupiterApi = (slippage: SlippageValues) => {
  let formatSlippage: Record<string, number> = {};
  Object.keys(slippage).forEach((key) => {
    formatSlippage[key] = Number(slippage[key as keyof typeof slippage]) * 100;
  });
  return formatSlippage;
};

export const convertIntoChartData = (
  data: any[],
  priceKey: string,
  timeKey: string
) => {
  const formatted: ChartData = [];
  data.forEach((singleData) => {
    formatted.push([singleData[timeKey], singleData[priceKey]]);
  });
  return formatted;
};

export const SOL_to_microLamports = (SOL: number) => SOL * 10 ** 9 * 1000000;
export const microLamports_to_SOL = (microLamports: number) =>
  microLamports / (10 ** 9 * 1000000);
