import { useMemo, useState } from "react";

import { dispatch, useSelector } from "Store";
import { setIsSwapSettingsOpen } from "Store/Reducers/session";
import backIcon from "Assets/Svgs/arrow_down.svg";
import { InfoTooltip } from "Components/tooltip";
import { resetSwapSettings, saveSwapSettings } from "Store/Reducers/app";
import SlippageSelection from "Components/slippageSelection";
import { SlippageLevels } from "Types/reducers";
import { AMOUNT_INPUT_REGEX } from "Constants/misc";
import { validateDecimalPlaces } from "Utils/judger";
import "./index.css";

const SwapSettingsModal = () => {
  const onlyDirectRoute = useSelector((state) => state.app.onlyDirectRoute);
  const versionedTx = useSelector((state) => state.app.versionedTx);
  const slippage = useSelector((state) => state.app.slippage);
  const currentSlippage = useSelector((state) => state.app.currentSlippage);
  const isSwapSettingsOpen = useSelector(
    (state) => state.session.isSwapSettingsOpen
  );

  const [directonlyLocal, setDirectonlyLocal] = useState(onlyDirectRoute);
  const [versionTxLocal, setVersionTxLocal] = useState(versionedTx);
  const [slippageLocal, setSlippageLocal] = useState(slippage);
  const [currentSlippageLocal, setCurrentSlippageLocal] =
    useState(currentSlippage);

  const closeSettingModal = () => {
    dispatch(setIsSwapSettingsOpen(false));
    resetToSavedChanges();
  };

  const resetToSavedChanges = () => {
    setDirectonlyLocal(onlyDirectRoute);
    setVersionTxLocal(versionedTx);
    setSlippageLocal({ ...slippage });
    setCurrentSlippageLocal(currentSlippage);
  };

  const resetToDefaulLocalChanges = () => {
    setDirectonlyLocal(false);
    setVersionTxLocal(true);
    setSlippageLocal({
      low: 0.5,
      medium: 1,
      high: 2,
      custom: "",
    });
    setCurrentSlippageLocal(SlippageLevels.medium);
  };

  const handleSlippageClick = (level: SlippageLevels) => {
    setCurrentSlippageLocal(level);
    clearCustomSlippageLocal();
  };

  const handleCustomSlippageChange = (text: string) => {
    if (text.match(AMOUNT_INPUT_REGEX)) {
      if (validateDecimalPlaces(text, 2)) {
        setSlippageLocal({
          ...slippageLocal,
          custom: text,
        });
      }
    } else {
      clearCustomSlippageLocal();
    }
  };

  const clearCustomSlippageLocal = () =>
    setSlippageLocal({ ...slippageLocal, custom: "" });

  const isDisabled = useMemo(
    () =>
      currentSlippageLocal === SlippageLevels.custom &&
      slippageLocal.custom === "",
    [currentSlippageLocal, slippageLocal.custom]
  );

  const handleSaveSwapSettings = () => {
    dispatch(
      saveSwapSettings({
        versionedTx: versionTxLocal,
        onlyDirectRoute: directonlyLocal,
        slippage: slippageLocal,
        currentSlippage: currentSlippageLocal,
      })
    );
    dispatch(setIsSwapSettingsOpen(false));
  };

  const handleReset = () => {
    resetToDefaulLocalChanges();
    dispatch(resetSwapSettings());
  };

  return (
    <dialog
      id="my_modal_2"
      className="modal"
      style={isSwapSettingsOpen ? { backdropFilter: "blur(10px)" } : {}}
      open={isSwapSettingsOpen}
      onClose={closeSettingModal}
    >
      <div className="modal-box gradient-border !p-[0.125rem] !rounded-[0.25rem]">
        <div className="bg-base-content !rounded-[0.25rem] p-6">
          <div className="flex justify-between items-center ">
            <img
              src={backIcon}
              className="cursor-pointer accent-on-hover"
              alt="backIcon"
              style={{ transform: "rotate(90deg)", width: "20px" }}
              onClick={closeSettingModal}
            />
            <div className="text-xl font-semibold text-primary">
              Swap settings
            </div>
            <p
              className="text-primary cursor-pointer accent-on-hover"
              onClick={handleReset}
            >
              Reset
            </p>
          </div>
          <div className="flex flex-col gap-7 mt-8">
            <SlippageSelection
              slippage={slippageLocal}
              currentSlippage={currentSlippageLocal}
              onSlippageClick={handleSlippageClick}
              onCustomSlippageChange={handleCustomSlippageChange}
            />
            <ToggleTile
              name="Direct Route Only"
              isEnable={directonlyLocal}
              info="It ensure only direct routing and also disable split trade trading."
              onToggleClick={() => setDirectonlyLocal(!directonlyLocal)}
            />
            <ToggleTile
              name="Versioned Transaction"
              isEnable={versionTxLocal}
              info="Versioned Tx is a significant upgrade that allows for more advanced routings and better prices! Make sure your connected wallet is compatible before toggling on Ver. Tx."
              onToggleClick={() => setVersionTxLocal(!versionTxLocal)}
            />
          </div>
          <div
            onClick={handleSaveSwapSettings}
            className={`btn w-full text-primary mesh-gradient text-center font-bold py-3.5 mt-10 rounded button-hover-swap ${
              isDisabled
                ? "cursor-not-allowed opacity-30"
                : "cursor-pointer opacity-100"
            }`}
          >
            Save Settings
          </div>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default SwapSettingsModal;

type ToggleTileProps = {
  name: string;
  isEnable: boolean;
  onToggleClick: () => void;
  info?: string;
};

const ToggleTile = ({
  name,
  isEnable,
  onToggleClick,
  info,
}: ToggleTileProps) => {
  return (
    <div className="flex items-center justify-between text-xs md:text-sm">
      <div className="flex items-center">
        <p className="text-secondary">{name}</p>
        {info && <InfoTooltip text={info} />}
      </div>
      <div className="flex items-center">
        <input
          type="checkbox"
          className={"toggle [--tglbg:black] bg-secondary toggle-sm md:toggle-md toggle-accent".concat(
            !isEnable ? " hover:bg-secondary" : ""
          )}
          checked={isEnable}
          onChange={onToggleClick}
        />
      </div>
    </div>
  );
};
