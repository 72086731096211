import { ReactNode } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import InfoIcon from "Assets/Svgs/info.svg";

type Props = {
  children: ReactNode;
  twClasses?: string;
  text: string;
};

export const Tooltip = ({ children, twClasses = "", text }: Props) => {
  return (
    <span
      className={`tooltip tooltip-bottom before:bg-[#2c2d30] before:text-primary w-fit before:max-w-[60vw] sm:before:max-w-[20rem] before:z-9999999 cursor-pointer ${twClasses}`}
      {...(text ? { "data-tip": text } : {})}
    >
      {children}
    </span>
  );
};

export const InfoTooltip = ({ text }: { text: string }) => {
  return (
    <>
      <ReactTooltip
        id={text}
        content={text}
        place="bottom"
        style={{ maxWidth: "300px" }}
      />
      <img
        data-tooltip-id={text}
        src={InfoIcon}
        alt="InfoIcon"
        className="ml-1"
      />
    </>
  );
};
